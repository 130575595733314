

class StringUtil{

    static shortenText(text, maxCharacters) {

        if(text==null){
            return "";
        }

        // Prüfen, ob der Text länger als 10 Zeichen ist
        if (text.length > maxCharacters) {
            return text.substring(0, maxCharacters) + '...';
        }
        return text; // Wenn der Text kürzer oder gleich 10 Zeichen ist, unverändert zurückgeben
    }

    static getRawUrlParams(url) {
        const params = {};
        let paramString = url;
        if (url.startsWith("?")) {
            // Entferne das führende "?" und splitte die Parameter
            paramString = url.substring(1);
        }
        const pairs = paramString.split("&");
        for (const pair of pairs) {
            // Teile Schlüssel und Wert bei "="
            const [key, value] = pair.split("=");
            // Speichere Schlüssel und Wert roh (ohne Dekodierung)
            params[key] = decodeURIComponent(value) || ""; // Wenn kein Wert vorhanden ist, setze ihn als leeren String
        }
        return params;
    }

    static objectToHash(object) {
        const str = JSON.stringify(object); // Array in einen String umwandeln
        let hash = 5381;
        for (let i = 0; i < str.length; i++) {
            hash = (hash * 33) ^ str.charCodeAt(i);
        }
        return hash >>> 0; // Hashwert als positive Zahl zurückgeben
    }


}
export default StringUtil;