import React, {Component} from 'react';

import './InstructorBadge.css'
import {
    faCheck, faCircle, faClock,
    faDraftingCompass, faExclamationCircle, faUser

} from "@fortawesome/free-solid-svg-icons";

import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EvaluationFormInput from "../WorksheetEditorComponents/EvaluationFormInput";
import PagePopup from "../PagePopup";

class StatusBadge extends Component {

static TYPES = {
        DRAFT_WORKING_SHEET : {
            state : 'DRAFT_WORKING_SHEET',
            text: 'Arbeitsblatt-Einrichtung noch nicht abgeschlossen',
            color: 'white',
            bgColor: '#222',
            icon: faDraftingCompass
        },
        OPEN_WORKING_SHEET : {
            state : 'OPEN_WORKING_SHEET',
            text: "Offen",
            color: 'white',
            bgColor: 'orange',
            icon: faCircle
        },
        SUBMITTED_WORKING_SHEET : {
            state : 'SUBMITTED_WORKING_SHEET',
            text: 'Arbeitsblatt wird bewertet',
            color: '#f1f1f1',
            bgColor: '#021260',
            icon: faClock
        },
        DISCARDED_WORKING_SHEET : {
            state : 'DISCARDED_WORKING_SHEET',
            text: 'Arbeitsblatt abgebrochen',
            color:'white',
            bgColor: 'red',
            icon: faExclamationCircle
        },
        GRADED_WORKING_SHEET : {
            state : 'GRADED_WORKING_SHEET',
            text: 'Bewertung abgeschlossen',
            color: 'white',
            bgColor: '#003300',
            icon: faCheck
        }
    }


    constructor(props) {
        super(props);
        this.course = props.course;
        this.worksheet = props.worksheet;
        this.smallSize = props.smallSize;
        this.changeable = props.changeable?props.changeable:false;
        this.onWorksheetUpdated = props.onWorksheetUpdated;
        this.onEvaluationUpdated = props.onEvaluationUpdated?props.onEvaluationUpdated:()=>{};
        this.state = {
            status: StatusBadge.getStatus(this.worksheet.state),
            popupProps: {
                title: "",
                body: "",
                show: false,
                showFooter: false
            },
        }

    }


    static getStatus(worksheetStatus){
        switch (worksheetStatus){
            case "DRAFT_WORKING_SHEET":
                return StatusBadge.TYPES.DRAFT_WORKING_SHEET;
            case "OPEN_WORKING_SHEET":
                return StatusBadge.TYPES.OPEN_WORKING_SHEET;
            case "SUBMITTED_WORKING_SHEET":
                return StatusBadge.TYPES.SUBMITTED_WORKING_SHEET
            case "GRADED_WORKING_SHEET":
                return StatusBadge.TYPES.GRADED_WORKING_SHEET
            case "DISCARDED_WORKING_SHEET":
                return StatusBadge.TYPES.DISCARDED_WORKING_SHEET
        }
    }

    getOtherStatus(worksheetStatus){
        switch (worksheetStatus){
            case "DRAFT_WORKING_SHEET":
                return [
                    StatusBadge.TYPES.DISCARDED_WORKING_SHEET,
                    StatusBadge.TYPES.OPEN_WORKING_SHEET,
                    StatusBadge.TYPES.SUBMITTED_WORKING_SHEET,
                    StatusBadge.TYPES.GRADED_WORKING_SHEET
                    ];
            case "OPEN_WORKING_SHEET":
                return [
                    StatusBadge.TYPES.SUBMITTED_WORKING_SHEET,
                    StatusBadge.TYPES.DISCARDED_WORKING_SHEET,
                    StatusBadge.TYPES.GRADED_WORKING_SHEET
                ];
            case "SUBMITTED_WORKING_SHEET":
                return [
                    StatusBadge.TYPES.OPEN_WORKING_SHEET,
                    StatusBadge.TYPES.DISCARDED_WORKING_SHEET,
                    StatusBadge.TYPES.GRADED_WORKING_SHEET
                ];
            case "GRADED_WORKING_SHEET":
                return [
                    StatusBadge.TYPES.OPEN_WORKING_SHEET,
                    StatusBadge.TYPES.SUBMITTED_WORKING_SHEET,
                    StatusBadge.TYPES.DISCARDED_WORKING_SHEET
                ];
            case "DISCARDED_WORKING_SHEET":
                return [
                    StatusBadge.TYPES.OPEN_WORKING_SHEET,
                    StatusBadge.TYPES.SUBMITTED_WORKING_SHEET
                ];
        }
    }


    handleEvaluateWorkingSheet() {
        var self = this;
        self.setState({
                popupProps: {
                    title: <h2><FontAwesomeIcon icon={faUser} /> {this.worksheet.trainee.name}, {this.worksheet.trainee.firstname}</h2>,
                    body: <EvaluationFormInput
                        worksheet={self.worksheet}
                        onFinishEvaluation={(updatedEvaluation) => {
                            self.onEvaluationUpdated(self.worksheet.traineeId, updatedEvaluation);
                            this.setState(prevState => ({
                                popupProps: {
                                    ...prevState.popupProps,
                                    show: false
                                }
                            }))
                        }} />,
                    show: true,
                    showFooter: false
                }
            }
        );
    }

    change(selectedStatus){
        let worksheet = this.worksheet;
        worksheet.state = selectedStatus;
        this.setState({
            status:  StatusBadge.getStatus(selectedStatus),
            worksheet: worksheet
        });
        this.onWorksheetUpdated(worksheet);
    }

    renderBadge(statusBadge,otherBadges){
        return (
            <>
                {!this.changeable &&
                <div style={{padding:'0em',marginLeft:'0.5em',display:'inline-block'}}>
                    <span style={{color:'white'}}>
                            <FontAwesomeIcon style={{color:statusBadge.color}}
                                             icon={statusBadge.icon}/> {this.smallSize?"":statusBadge.text}</span>
                </div>
                }
                {this.changeable &&
                <Dropdown style={{display:'inline-block'}}>
                    <Dropdown.Toggle variant="success" style={{background:statusBadge.bgColor}} id="dropdown-basic">
                        <span style={{color:'white'}}>
                            <FontAwesomeIcon style={{color:statusBadge.color}}
                                             icon={statusBadge.icon}/> {this.smallSize?"":statusBadge.text}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {otherBadges.map((status, index) => (
                            <Dropdown.Item onClickCapture={() => this.change(status.state)} key={index}>
                                 <span style={{color:'#222'}}>
                            <FontAwesomeIcon style={{color:'#222'}}
                                             icon={status.icon}/> {status.text}</span>
                            </Dropdown.Item>
                        ))}

                    </Dropdown.Menu>
                </Dropdown>
                }
            </>
        )
    }

    render() {
        return(
            <div className={"statusBadge"} style={{display:"inline-block"}}>
                <PagePopup
                    className={"evaluationPopup"}
                    onClose={()=>this.setState({popupProps:{show:false}})}
                    key={this.state.popupProps.show}
                    {...this.state.popupProps}>
                </PagePopup>
                {this.renderBadge(this.state.status,this.getOtherStatus(this.state.status.state))}
            </div>
        )
    }
}
export default StatusBadge;