import React, {Component} from "react";
import LoadingScreen from "../../includes/LoadingScreen";
import APIController from "../../controller/APIController";
import './WorksheetPrintPage.css';
import {Alert, Badge, Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faDownload,
    faFile,
    faFilePdf, faProjectDiagram,
    faRefresh,
    faUpload, faUsers
} from "@fortawesome/free-solid-svg-icons";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {SizeMe} from "react-sizeme";
import UserController from "../../controller/UserController";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

class WorksheetPrintPage extends Component {
    constructor(props) {
        super(props);
        this.handleExportPDFClicked = this.handleExportPDFClicked.bind(this);
        this.state = {
            worksheetId: props.worksheetId,
            traineeId: props.traineeId,
            trainee: null,
            isFetching: true,
            worksheet : null,
            access_rights: null,
            pageNumber: 1,
            numPages: null
        };
    }

    onDocumentLoad = ({ numPages }) => {
        this.setState(prevState => ({
            ...prevState,
            numPages: numPages,
        }));
    }

    onDocumentLoadError = ({ e }) => {
        window.alert("Fehler beim Laden der PDF-Datei: "+e.toString())
    }

    async handleExportPDFClicked() {
        this.setState(prevState => ({
            ...prevState,
            isFetching: true,
        }));
        let blob = await fetch(this.state.fileSrc).then(r => r.blob());
        let result = await APIController.sendPDFBlobToDocuware(
            blob,
            this.getFileName(),
            this.state.worksheetId
        );


        this.setState(prevState => ({
            ...prevState,
            isFetching: false,
        }));
    }


    componentDidCatch(error, errorInfo) {
        // Fehlerinformationen für Logging oder Reporting erfassen
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
        this.setState()
        this.setState(prevState => ({
            ...prevState,
            error: errorInfo
        }));
    }


    async componentDidMount(){
        try {
            const worksheetId = encodeURIComponent(this.state.worksheetId);
            const worksheet = await APIController.getWorksheetById(worksheetId);
            const trainee = await APIController.getTraineeById(worksheet.traineeId);
            let pdfBlobFileSrc = await APIController.getPDFFile(this.state.worksheetId);
            this.setState(prevState => ({
                ...prevState,
                isFetching: false,
                worksheet: worksheet,
                fileSrc : pdfBlobFileSrc,
                trainee:trainee
            }));
        } catch (e) {
            console.log(e)
            this.setState(prevState => ({
                ...prevState,
                isFetching : false,
                fileSrc: null,
                worksheet: null,
                trainee:null
            }));
        }
    }

    getFileName(){
        let ws = this.state.worksheet;
        let t = this.state.trainee;
        return t.name+"_"+t.groupName+"_"+ws.courseId+"_"+ws.topic+".pdf";
    }

    getPDFTitle(){
        let ws = this.state.worksheet;
        let t = this.state.trainee;
        return <span>{t.name+", "+t.firstname} <small><Badge bg={"dark"}><FontAwesomeIcon icon={faProjectDiagram} /> {ws.topic}</Badge> <Badge bg={"dark"}><FontAwesomeIcon icon={faUsers} /> {t.groupName}</Badge></small></span>
    }

    render() {
        if(this.state.error){
            return this._render(
                this._renderError()
            )
        }else if(!this.state.isFetching && this.state.fileSrc == null){
            return this._render(
                <Alert variant={"danger"}>Arbeistblatt konnte nicht geöffnet werden</Alert>
            )
        }else if(this.state.isFetching){
            return this._render(
                <LoadingScreen><FontAwesomeIcon icon={faFilePdf} /> PDF-Datei wird vorbereitet</LoadingScreen>
            )
        }else{
            return this._render(
                <>
                    <h2><FontAwesomeIcon icon={faFilePdf} /> PDF-Datei Export</h2>
                    <h5>{this.getPDFTitle()}</h5>
                    { this._renderMain()}
                </>
            )
        }
    }



    _renderError(){
        return (
            <>
                <Alert variant={"danger"}>
                    <h3>Ups! Da ist ein technischer Fehler unterlaufen.</h3>
                    Leider konnte die PDF-Datei nicht generiert werden. Bitte versuche es erneut.
                    Sollte das Problem weiterhin bestehen, kontaktiere uns gerne – wir helfen Ihnen weiter!
                </Alert>
                {/* eslint-disable-next-line no-restricted-globals */}
                <Button style={{marginRight:'1em'}} variant={"light"} onClick={() => history.back()}><FontAwesomeIcon icon={faArrowLeft} /> Zurück</Button>
                <Button variant={"light"} onClick={() => window.location.reload()}><FontAwesomeIcon icon={faRefresh} /> Seite neu laden</Button>
            </>
        )
    }

    _renderMain(){
        return <>
            <p>
                <div style={{display:"inline-block", marginRight:'0.5em'}}><span>Seitenanzahl: <Badge bg={"dark"}>{this.state.numPages}</Badge></span></div>
                <div className={"buttonPanel"} style={{display: "inline-block"}}>
                    <Button variant="warning" href={this.state.fileSrc} style={{marginRight:'1em'}} download={this.getFileName()}>
                        <FontAwesomeIcon icon={faDownload}  /> PDF Download
                    </Button>
                    {UserController.getUser().type === 'instructor' &&
                        <Button disabled={true} variant="dark" onClick={() => {this.handleExportPDFClicked()}}>
                            <FontAwesomeIcon icon={faUpload} /> Upload to DocuWare
                        </Button>
                    }
                </div>
            </p>
            <Document
                onLoadSuccess={this.onDocumentLoad}
                file={this.state.fileSrc}
                onLoadError={this.onDocumentLoadError}
            >
                <Row>
                    {this.state.numPages !== null &&
                        <>
                            {Array.from({ length: this.state.numPages }, (_, i) => i + 1).map((i)  => {
                                return <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                                    <div className={"pdf-file"}>
                                        <SizeMe
                                            monitorHeight
                                            refreshRate={128}
                                            refreshMode={"debounce"}
                                            render={({ size }) => (
                                                <div>
                                                    <div className={"pdfPageTitle"}><FontAwesomeIcon icon={faFilePdf} /> Seite {i}</div>
                                                    <Page height={size.height} width={size.width} pageNumber={i} />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </Col>
                            })}
                        </>
                    }
                </Row>
            </Document>
        </>
    }

    _render(children = null) {
        return (
            <div className="WorksheetPrintPage">
                <div className={"pdf-wrapper"} style={{paddingTop:'1em'}}>
                    {children!== null &&
                        children
                    }
                </div>
            </div>
        );
    }
}

export default WorksheetPrintPage;