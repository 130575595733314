import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import {Badge, Col, Row} from "react-bootstrap";

export default function SimpleGallery(props) {
    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#' + props.galleryID,
            children: 'a',
            pswpModule: () => import('photoswipe'),
            bgOpacity: 0.5,
            spacing: 0.1,
            allowPanToNext: true,
            loop: false,
            pinchToClose: true,
            closeOnVerticalDrag: true,
            closeTitle: 'Close the dialog',
            zoomTitle: 'Zoom the photo',
            arrowPrevTitle: 'Go to the previous photo',
            arrowNextTitle: 'Go to the next photo',
            showHideAnimationType: 'zoom',
            arrowPrev: true,
            arrowNext: true,
            zoom: true,
            close: true,
            counter: true

        });
        lightbox.init();

        return () => {
            lightbox.destroy();
            lightbox = null;
        };
    }, []);

    let columnSize = 12;
    if(props.size){
        columnSize = props.size;
    }else{
        if(props.images.length <= 1){
            columnSize = 12;
        }
        if(props.images.length <= 3){
            columnSize = 6;
        }
        if(props.images.length >= 4){
            columnSize = 4;
        }
    }


    if(props.singleImage){
        let image = props.images[0];
        return (
            <div style={{width:'100%'}} className="pswp-gallery" id={props.galleryID}>
                <a
                    href={image.largeURL}
                    data-pswp-width={image.width}
                    data-pswp-height={image.height}
                    key={props.galleryID+"singleimage"}
                    target="_blank"
                    rel="noreferrer"
                >
                    <img alt={image.desc} style={{maxWidth:'100%'}} src={image.thumbnailURL} />
                </a>
            </div>
        );
    }else{
        return (
            <div style={{width:'100%'}} className="pswp-gallery" id={props.galleryID}>
                <Row>
                    {props.images.map((image, index) => (
                        <Col xs={12} lg={columnSize}>
                            <div className={"imageWrapper"}>
                                <a
                                    href={image.largeURL}
                                    data-pswp-width={image.width}
                                    data-pswp-height={image.height}
                                    key={props.galleryID + '-' + index}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img alt={image.desc} style={{maxWidth:'100%'}} src={image.thumbnailURL} />
                                </a><br />
                                <Badge bg={"dark"}>{image.extension}</Badge> <span>{image.description}</span>
                            </div>

                        </Col>
                    ))}
                </Row>
            </div>
        );
    }
}