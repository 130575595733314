import React, {Component} from 'react';


import config from "../../config";
import loading from "../../assets/loading.gif";
import APIController from "../../controller/APIController";
import {Badge, Button, Col, Container, Row, Table} from "react-bootstrap";
import NumberInputFormField from "../../components/formFields/NumberInputFormField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './TopicsListPage.css'
import {
    faArrowLeft,
    faArrowRight, faArrowRightLong, faArrowRightToBracket, faChain, faChainBroken, faCircleNodes, faClock, faCodeMerge,
    faCubes,
    faEdit,
    faEye, faFileText, faList,
    faProjectDiagram, faSheetPlastic, faSignature, faSync,
    faTrash, faUsers, faWarning
} from "@fortawesome/free-solid-svg-icons";
import PageHeader from "../../includes/PageHeader";
import LoadingScreen from "../../includes/LoadingScreen";
import ProjectsTable from "../../components/TemplatesTable";
import {Link} from "react-router-dom";
import DateTimeUtil from "../../util/DateTimeUtil";
import SingleSelectInputFormField from "../../components/formFields/SingleSelectInputFormField";
import DataTablePageHeader from "../../includes/DatatablePageHeader";
import PageContent from "../../includes/PageContent";
import TopicBadges from "../../components/badges/TopicBadges";
import GenericBadges from "../../components/badges/GenericBadges";
class TopicsListPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            reload:true,
            page:1,
            pagesTotel:0,
            numTotal:0,
            topics : null,
            connectedFilter:null
        };
    }



    async reload(){
        try {
            this.setState({
                isFetching:true
            })

            let queryParams = {
                page: this.state.page,
            };
            if(this.state.connectedFilter != null){
                queryParams["connectedProjects"] = this.state.connectedFilter;
            }


            let params = new URLSearchParams(queryParams);
            const json = await APIController.getTopicsListAdmin(params);
            this.setState({
                isFetching: false,
                reload:false,
                topics : json.data,
                pagesTotel :json.pages_total,
                numTotal: json.num_total
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                topics : null
            });
        }
    }


    async componentDidMount() {
        await this.reload();
    }


    render() {

        if(!this.state.isFetching && this.state.topics == null){
            return <div>error</div>
        }


        return (
            <div>
                <DataTablePageHeader
                    title={"Projekte"}
                    fontAwesomeIcon={faProjectDiagram}
                    key={this.state.isFetching}
                    isFetching={this.state.isFetching}
                    numTotal={this.state.numTotal}
                    pagesTotel={this.state.pagesTotel}
                    page={this.state.page}
                    onPageChange={(page)=>{
                        this.state.page = page;
                        this.reload()}}
                    selectionOptions={[
                        {name: "verbunden", value: true},
                        {name: "nicht verbunden", value: false},
                        {name: "Alle anzeigen", value: null},
                    ]}
                    selectedOptionValue={this.state.connectedFilter}
                    onOptionValueSelected={(value)=>{
                        if(this.state.connectedFilter != null){
                            this.state.page = 1;
                        }
                        this.state.connectedFilter = value;
                        this.reload()}}
                >
                </DataTablePageHeader>

                {this.state.isFetching? (<LoadingScreen />):(
                    <>
                    <PageContent>
                            <Table className={"table-responsive styledTable"}>
                                <tbody>
                                {this.state.topics.map( (topic, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <Row>
                                                    <Col xs={12} lg={8} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                        <span className={'rowTitle'} style={{fontWeight:'bold'}}><FontAwesomeIcon icon={faProjectDiagram} /> {topic.projectId}</span>
                                                    </Col>

{/*                                                    <Col xs={12} lg={1} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                                                        <span><b>{topic.names.length}</b> <FontAwesomeIcon icon={faCodeMerge} /></span>
                                                    </Col>

                                                    <Col xs={12} lg={5} >
                                                        <GenericBadges elementTitles={topic.names} icon={faProjectDiagram} />
                                                    </Col>*/}
                                                    <Col xs={12} lg={4} style={{display: (this.state.connectedFilter !== null) ? "flex" : "none",justifyContent:'right',alignItems:'center'}}>
                                                        {topic.connectedProject == null ?
                                                            <Badge style={{width:'10em'}} className={"projectIdBadge"} bg="danger"><FontAwesomeIcon icon={faChainBroken} /> nicht verbunden</Badge>
                                                            :
                                                            <span><FontAwesomeIcon icon={faArrowRightLong} /> <FontAwesomeIcon icon={faSheetPlastic} /> {topic.connectedProject.title}</span>
                                                        }
                                                    </Col>
                                                </Row>
                                            </td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                    </PageContent>

                    </>
                )}
            </div>
        );
    }
}


export default TopicsListPage;