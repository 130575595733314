import moment from "moment";
import 'moment/locale/de'

class DateTimeUtil{

    /*
    https://momentjs.com/docs/#/displaying/
     */
    static getHumanReadableDate(milliseconds, showYear= true){
        var date = new Date(milliseconds);
        var momentObj = moment(date);
        momentObj.locale('de');
        if(showYear){
            return momentObj.format("DD.MM.YYYY");
        }else{
            return momentObj.format("DD.MM");
        }
    }



    static getDaysBetween(date1,date2){
        let Difference_In_Time =
            date2.getTime() - date1.getTime();
        // Calculating the no. of days between
        // two dates
        let Difference_In_Days =
            Math.round
            (Difference_In_Time / (1000 * 3600 * 24));
        return Difference_In_Days;
    }

    static getMinutesBetween(date1,date2){
        let Difference_In_Time =
            date2.getTime() - date1.getTime();
        // Calculating the no. of days between
        // two dates
        let Difference_In_Days =
            Math.round
            (Difference_In_Time / (1000 * 60));
        return Difference_In_Days;
    }

    static randomDate(start, end) {
        let date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
        var momentObj = moment(date);
        momentObj.locale('de');
        return momentObj.format("DD.MM.YYYY");
    }

    static parseDateOfYear(str){
        const myDate = moment(str, 'YYYY-MM-DD').toDate();
        let m = moment(myDate);
        m.locale('de');
        return m.format('LL');;
    }

    /**
     * 1959-12-31T23:00:00.000Z => 31.12.1959
     * @param isoDate 1959-12-31T23:00:00.000Z
     * @returns {string}
     */
    static parseIsoDateOfYear(isoDate){
        const date = new Date(isoDate);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Monate sind 0-basiert
        const year = date.getUTCFullYear();
        return `${day}.${month}.${year}`;
    }

    static toDistanceString(millisA){
        const milA = moment(millisA).fromNow();
        return milA;
    }

    static getTodayZeroMillis(){
        var d = new Date();
        d.setHours(0,0,0,0);
        return d.getTime();
    }

    static getCurrentWeekNumber(){
        return moment().format('WW');
    }

    static getCurrentKW(){
        let weekNumber = parseInt(DateTimeUtil.getCurrentWeekNumber());
        let year = new Date().getFullYear();
        return DateTimeUtil.getKW(weekNumber,year);
    }

    static getSundayFromWeekNum(weekNum, year) {
        let sunday  = moment().year(year).hours(0).minutes(0).seconds(0).milliseconds(0)
            .day(0).week(weekNum);
        sunday.year(year);
        return sunday;
    }
    static getMondayFromWeekNum(weekNum, year) {
        let monday  = moment().year(year).hours(0).minutes(0).seconds(0).milliseconds(0)
            .day(0).week(weekNum).subtract(6,'days');
        monday.year(year);
        return monday;
    }
    static getWeek(time) {
        var date = new Date();
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        var week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    static getDateRangeOfWeek(weekNo, y){
        var d1, numOfdaysPastSinceLastMonday, rangeIsFrom, rangeIsTo;
        d1 = new Date(''+y+'');
        numOfdaysPastSinceLastMonday = d1.getDay() - 1;
        d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
        d1.setDate(d1.getDate() + (7 * (weekNo - DateTimeUtil.getWeek(d1.getTime()))));
        rangeIsFrom = (d1.getMonth() + 1) + "-" + d1.getDate() + "-" + d1.getFullYear();
        d1.setDate(d1.getDate() + 6);
        rangeIsTo = (d1.getMonth() + 1) + "-" + d1.getDate() + "-" + d1.getFullYear() ;
        return rangeIsFrom + " to " + rangeIsTo;
    };

    static getKW(weekNumber,year){


        let sunday = this.getSundayFromWeekNum(weekNumber,year);
        let monday = this.getMondayFromWeekNum(weekNumber,year);

        const zeroPad = (num, places) => String(num).padStart(places, '0')

        const mDay     = zeroPad(monday.toDate().getDate(),2);
        const mMonth   = zeroPad(monday.toDate().getMonth()+1,2); // months from 1-12
        const mYear    = monday.year();

        const sDay     = zeroPad(sunday.toDate().getDate(),2);
        const sMonth   = zeroPad(sunday.toDate().getMonth()+1,2); // months from 1-12
        const sYear    = sunday.year();


        return {
            'year':parseInt(year),
            'weekNumber':parseInt(weekNumber),
            'monday':mDay+"."+mMonth+"."+mYear,
            'sunday':sDay+"."+sMonth+"."+sYear,
            'dateTimespan':mDay+"."+mMonth+" - "+sDay+"."+sMonth+"."+sYear,
            'mondayDate': monday.format('yyy-MM-DD'),
            'sundayDate': sunday.format('yyy-MM-DD')
        }

    }


    static getHumanReadableTime(milliseconds){
        var date = new Date(milliseconds);
        var momentObj = moment(date);
        momentObj.locale('de');
        return momentObj.format("HH:mm");
    }

    static getHumanReadableDateTime(milliseconds){
        return DateTimeUtil.getHumanReadableDate(milliseconds)
            +" "
            + DateTimeUtil.getHumanReadableTime(milliseconds)
    }

    static lessThanHoursAndMinutesAgoStatusText(timestamp, hours= 2, minutes= 30){
        let date = new Date(timestamp);
        if (moment(date).add(hours, 'hour').isBefore(/*now*/)) return 'Gestoppt';
        if (moment(date).add(minutes,  'minutes').isBefore(/*now*/)) return 'Vielleicht funktioniert';
        return 'Funktioniert';
    }
}
export default DateTimeUtil;