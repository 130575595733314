import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { msalConfig } from './msalAuthConfig';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import UserController from "./controller/UserController";


const msalInstance = new PublicClientApplication(msalConfig);
/*
var request = {
    scopes: ["User.Read"],
};
msalInstance.initialize().then(()=>{
    msalInstance.acquireTokenSilent(request).then(tokenResponse => {
        // Do something with the tokenResponse
        console.log(tokenResponse);
        window.alert("tokenResponse")
    }).catch(async (error) => {
        console.log(error)
        // handle other errors
    })
})
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    let activeAccount = msalInstance.getActiveAccount()[0];

    msalInstance.setActiveAccount(activeAccount);
}
*/


// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        //UserController.setSessionToken(event.payload.idToken); //store jwt token in user controller sessions
        msalInstance.setActiveAccount(account);
    }

    if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        const account = event.payload.account;
        //UserController.setSessionToken(event.payload.idToken); //store jwt token in user controller sessions
        msalInstance.setActiveAccount(account);
        let sessionToken = event.payload.idToken;
        UserController.getUniPlusUserByJWTToken(sessionToken).then(user => {
            if(user == null){
                UserController.logout();
            }else{
                let userType = user.type;
                let email = user.email; //correct here?
                let id = user.id;
                UserController.loginLocalUser(
                    sessionToken,
                    userType,
                    email,
                    id
                )
            }
        });


    }
});


const root = ReactDOM.createRoot(document.getElementById('root'));

/*
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/
root.render(
        <App instance={msalInstance}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
