import {getImageSize} from "react-image-size";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExternalLink} from "@fortawesome/free-solid-svg-icons";
import {Badge, Button} from "react-bootstrap";
import React from "react";
import SimpleGallery from "../components/SimpleGallery";
import ModelViewer from "../components/3DViewer";
import {v4 as uuidv4} from "uuid";


class FileUtil{


    // Definition einer öffentlichen "Enum" als statische Konstante
    static MEDIA_TYPES = Object.freeze({
        IMAGE: 'image',
        MODEL: 'model',
        OBJECT: 'object'
    });

    static getExtensionWhitelist(){
        return ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.svg', '.webp',
        '.mp3', '.wav', '.ogg', '.flac',
        '.mp4', '.mov', '.avi', '.webm', '.mkv', '.wmv',
        '.txt', '.csv',
        '.pdf','.xls','.xlsx','.fsprj'];
    }

    /**
     * Loads extension from filepath like '/home/test/file.exe'=> '.exe'
     * @param filePath
     */
    static getFileExtension(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 1).toLowerCase();
    }

    /**
     * Checks if thefilepath like '/home/test/file.exe' has a dangerous filetype like '.exe'
     * @param filePath
     * @returns {boolean}
     */
    static isDangerousFile(filePath){
        let fileExtension = FileUtil.getFileExtension(filePath);
        const dangerousExtensions = [
            ".exe", ".bat", ".cmd", ".js", ".vbs", ".sh", ".scr", ".msi", ".dll"
        ];
        return dangerousExtensions.includes(fileExtension);
    }


    /**
     * Seperates media files in three different render types: images, models, objects
     * Loads imageFile asynchronysly to get image dimensions and attach them to images.
     * These values are nesccesarry for dynamic image gallery popup
     * @param files
     * @returns {Promise<{filesWithMeta: *[]}>}
     */
    static async loadMediaFilesMeta(files){
        let filesWithMeta = [];

        for (let idx = 0; idx < files.length; idx++) {
            const file = files[idx];
            if (FileUtil.canRenderInImgTag(file.type)) {
                file.mediaType = FileUtil.MEDIA_TYPES.IMAGE;
                const dimensions = await getImageSize(file.url);
                file.width = dimensions?.width;
                file.height = dimensions?.height;
                file.largeURL = file.url;
                file.thumbnailURL = file.url;
                filesWithMeta.push(file);
            }else if(file.type === "application/octet-stream"){
                file.mediaType = FileUtil.MEDIA_TYPES.MODEL;
                filesWithMeta.push(file);
            }else{
                file.mediaType = FileUtil.MEDIA_TYPES.OBJECT;
                filesWithMeta.push(file);
            }
        }
        return filesWithMeta;
    }

    static canRenderInImgTag(mimeType) {
        // Liste der unterstützten MIME-Typen für <img>
        const supportedImageMimeTypes = [
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/webp",
            "image/svg+xml",
            "image/bmp",
            "image/x-icon",
            "image/avif"
        ];
        return supportedImageMimeTypes.includes(mimeType.toLowerCase());
    }


    /**
     * See also FileUtil.loadMediaFilesMeta
     * @param filesWithMeta *[]
     * @param printView
     * @param smallSize
     * @returns {Promise<JSX.Element>}
     */
    static renderFiles(filesWithMeta = [], printView=false, smallSize = false){
        let uuid = uuidv4();
        let imageFiles = [];
        let model3DFiles = [];
        let objectFiles = [];

        filesWithMeta.forEach(function(file) {
            if(file.mediaType === FileUtil.MEDIA_TYPES.IMAGE){
                imageFiles.push(file);
            }else if(file.mediaType === FileUtil.MEDIA_TYPES.MODEL){
                model3DFiles.push(file);
            }else if(file.mediaType === FileUtil.MEDIA_TYPES.OBJECT){
                objectFiles.push(file);
            }
        });

        return (
            <div className={"dynamicMediaGallery"}>
                {imageFiles.length > 0 &&
                    <SimpleGallery
                        key={"gal_"+uuid}
                        galleryID={"my-gallery-"+uuid}
                        images={imageFiles}
                    />
                }
                {model3DFiles.map(file => (
                    this.renderModelView(file)
                ))}
                {objectFiles.map(file => (
                    this.renderObjectFileView(file)
                ))}
                <div style={{clear:"both"}}></div>
            </div>
        );
    }


    /**
     * See also FileUtil.loadMediaFilesMeta
     * @param filesWithMeta *[]
     * @param printView
     * @param smallSize
     * @returns {Promise<JSX.Element>}
     */
    static renderTitleImageFiles(filesWithMeta = [], printView=false, smallSize = false, singleImage = false){
        let uuid = uuidv4();
        let imageFiles = [];

        filesWithMeta.forEach(function(file) {
            if(file.mediaType === FileUtil.MEDIA_TYPES.IMAGE){
                imageFiles.push(file);
            }
        });

        return (
            <div className={"dynamicMediaGallery"}>
                {imageFiles.length > 0 &&
                    <SimpleGallery
                        singleImage={singleImage}
                        key={"gal_"+uuid}
                        galleryID={"my-gallery-"+uuid}
                        images={imageFiles}
                    />
                }
                <div style={{clear:"both"}}></div>
            </div>
        );
    }

    static renderFile(file){
        if(file.mediaType === FileUtil.MEDIA_TYPES.IMAGE){
            return  <div className={"dynamicMediaGallery"}>{this.renderImageFileView(file)}</div>
        }else if(file.mediaType === FileUtil.MEDIA_TYPES.MODEL){
            return  <div className={"dynamicMediaGallery"}>{this.renderModelView(file)}</div>
        }else if(file.mediaType === FileUtil.MEDIA_TYPES.OBJECT){
            return  <div className={"dynamicMediaGallery"}>{this.renderObjectFileView(file)}</div>
        }
    }

    static renderModelView(file, printView=false, smallSize = false){
        return (
            <div key={file.id} className={smallSize?"modelWrapperOuter small":"modelWrapperOuter"}>
                <div>
                    <ModelViewer file={file}/>
                </div>
                <Badge bg={"dark"}>{file.extension}</Badge> <span>{file.description}</span>
            </div>
        )
    }
    static renderObjectFileView(file, printView=false, smallSize = false){
        return (
            <div key={file.id} className={smallSize?"imageWrapperOuter small":"imageWrapperOuter"}>
                <div>
                    <div key={file.id} className={"imageWrapper"}>
                        {printView?
                            <a style={{textDecoration:'none'}} href={file.url}><FontAwesomeIcon icon={faExternalLink} /> {file.url}</a>
                            :
                            <Button variant={"light"} onClick={()=>{
                                window.open(file.url)
                            }
                            }><FontAwesomeIcon icon={faDownload}/> Datei downloaden</Button>
                        }
                    </div>
                </div><br />
                <Badge bg={"dark"}>{file.extension}</Badge> <span>{file.description}</span>
            </div>
        )
    }
    static renderImageFileView(file, printView=false, smallSize = false){
        let imageFiles = [];
        let uuid = uuidv4();
        imageFiles.push(file)
        return (
            <SimpleGallery
                size={12}
                key={"gal_"+uuid}
                galleryID={"my-gallery-"+uuid}
                images={imageFiles}
            />
        )
    }

}
export default FileUtil;