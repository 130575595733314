import React, { Component } from 'react';

import './EvaluationResultsTable.css';
import EvaluationFormInput from "./WorksheetEditorComponents/EvaluationFormInput";
import {Alert} from "react-bootstrap";


class EvaluationResultsTable extends Component {

    constructor(props) {
        super(props);
        let formattedEvaluation = EvaluationFormInput.getFormattedEvaluation(JSON.parse(JSON.stringify(props.evaluation)));
        formattedEvaluation = EvaluationFormInput.recalculatePraktischeFetigkeitenSumResult(formattedEvaluation);
        this.evaluation = EvaluationFormInput.getStringFormattedEvaluation(formattedEvaluation);
        this.header = props.header || false;

        this.state = {
            error: false
        }
    }

    componentDidCatch(error, errorInfo) {
        // Fehlerinformationen für Logging oder Reporting erfassen
        console.error("Error caught by EvaluationResultsTable:", error, errorInfo);
        this.setState(prevState => ({
            ...prevState,
            error: errorInfo
        }));
    }

    render() {


        if(this.state.error){
            return (
                <div className={"EvaluationResults"}>
                    <h2>EvaluationResults konnte nicht geladen werden</h2>
                    <Alert variant={"danger"}>
                        {JSON.stringify(this.state.error)}
                    </Alert>
                </div>
            );
        }

        return (
            <div className={"EvaluationResults"}>
                {this.header &&
                    <>{this.header}</>
                }
                <table className={"EvaluationResultsTable"}>
                    <tbody>
                    <tr>
                        <td>Arbeitsvorbereitung</td>
                        <td>{EvaluationFormInput.getSimpleEvaluationMeasureName(this.evaluation.arbeitsvorbereitung)}</td>
                    </tr>
                    <tr>
                        <td>Praktische Fertigkeiten</td>
                        <td>
                            {EvaluationFormInput.getPracticalSkillsConclusionOptionsName(this.evaluation.praktische_fertigkeiten.grade)}
                            {this.evaluation.praktische_fertigkeiten.points !== "-1.0" &&
                                <span> ({this.evaluation.praktische_fertigkeiten.points} / 25.0 Punkte)</span>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>Arbeitstempo</td>
                        <td>{EvaluationFormInput.getWorkPaceOptionsMeasureName(this.evaluation.arbeitstempo)}</td>
                    </tr>
                    <tr>
                        <td>Arbeitshaltung</td>
                        <td>{EvaluationFormInput.getWorkPostureName(this.evaluation.arbeitshaltung)}</td>
                    </tr>
                    <tr>
                        <td>QS / Dokumentation</td>
                        <td>{EvaluationFormInput.getSimpleEvaluationMeasureName(this.evaluation.qs_dokumentation)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}


export default EvaluationResultsTable;