import React, {Component, useEffect, useState} from "react";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import logo from "../../assets/bauabc_logo.PNG";
import * as formik from "formik";
import * as yup from "yup";
import UserController from "../../controller/UserController";
import APIController from "../../controller/APIController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faHourglassStart} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import config from "../../config";

const ForgotPasswordFormComponent = (props) => {
    const { Formik, Field } = formik;
    const schema = yup.object().shape({
        email: yup.string().required("E-mail kann nicht leer sein").email("Ungültige E-Mail"),
    });
    const [initialValues, setInitialValues] = React.useState({email:""});
    const [apiResponseMessage, setApiResponseMessage] = useState("")
    const [inProgress, setInProgress] = useState(false);
    const [alertType, setAlertType] = useState("alert-danger");

    useEffect(() => {
        const errorMessageCleaner = setInterval(() => {
            if(apiResponseMessage !== ""){
                setApiResponseMessage("");
                setAlertType("alert-danger");
            }
        }, config.API_MESSSAGE_SHOW_TIMER);
        return () => clearInterval(errorMessageCleaner);
    }, [apiResponseMessage, alertType]);

    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={ async (values, { resetForm }) => {
                try {

                    setApiResponseMessage("");
                    setInProgress(true);

                    let payLoadEmail = values.email;
                    let isAccountValid = await APIController.requestForgotPasswordEmail(payLoadEmail);
                    if(isAccountValid.valid){
                        setAlertType("alert-success");
                        setApiResponseMessage("Deine E-Mail ist gültig. Bitte öffne dein E-Mail Postfach um dein Passwort zurückzusetzen")
                        resetForm();
                        setInProgress(false);
                    }else{
                        resetForm();
                        setAlertType("alert-warning");
                        setApiResponseMessage("Tut mir leid, dass du kein Konto hast. Stelle sicher, dass du eingeladen wurdest");
                        setInProgress(false);
                    }
                }
                catch (error){
                    resetForm();
                    setInProgress(false);
                    setApiResponseMessage(error.message);
                }
            }}>
            {({ handleSubmit, setFieldValue, values, handleBlur, touched, errors}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            Nutzerkonto E-Mail-Adresse
                        </Form.Label>
                        <Form.Control type="email" placeholder="E-mail eingeben" id="email" value={values.email}
                                      onBlur={handleBlur}
                                      isInvalid={touched.email && !!errors.email}
                                      onChange={(e) => setFieldValue( "email",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {apiResponseMessage &&
                        <div className={"mt-3 mb-3 alert " + alertType } dangerouslySetInnerHTML={{__html: apiResponseMessage}}/>
                    }
                    <div className="d-grid">
                        <div align={'center'}>
                            <div style={{display: 'inline-block', marginRight: '0.5em'}}>
                                {!inProgress ?
                                    <Button
                                        type="submit"
                                        variant="outline-secondary">
                                        Neues Passwort anfordern
                                    </Button> :
                                    <span>Bitte warten <FontAwesomeIcon icon={faHourglassStart}/></span>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}



class ForgotPassword extends Component {

    constructor(props){
        super(props);
        if(UserController.isLoggedIn()){

            window.location = UserController.getMyCoursesUrl();
        }
        this.params = props.params;
        this.state = {}
    }

    render() {
        return(
            <>
                <div className="Forgot-password" style={{marginTop:'1em'}}>
                    <Container>
                        <Row className="vh-100 d-flex justify-content-center align-items-center">
                            <Col md={8} lg={6} xs={12}>
                                <Card className="shadow">
                                    <Card.Body>
                                    <div align={'center'}>
                                        <img src={logo} style={{height: "80px"}} className="App-logo" alt="logo"/>
                                    </div>
                                        <div align={'center'}>
                                            <h2 className="fw-bold mb-3 text-uppercase ">
                                                Passwort vergessen
                                            </h2>
                                        </div>

                                    <div className="mb-3 mt-md-4">
                                        <div align={'center'}>
                                            {<p className="fw-light mb-4 ">
                                                Wenn du deine <b>Nutzerkonto E-Mail-Adresse</b> unter <b>my.ausbildung.cloud</b> kennst,
                                                dann kannst du hier ein neues Passwort anfordern
                                            </p>}

                                        </div>
                                        {<div className="mb-3 mt-md-4">
                                            <ForgotPasswordFormComponent state={this.state} />
                                        </div>}
                                        <hr />
                                        <div align={'center'}>
                                            <div style={{height:'1em'}}></div>
                                            <h4 className="fw-bold mb-3 mt-3 text-uppercase ">
                                                <b>Du hast eine TAN zur Konto Aktvierung?</b>
                                            </h4>
                                            {/*<p className="fw-light mb-4 ">
                                                Ein Link zum Zurücksetzen Ihres Passworts wird Ihnen per E-Mail
                                                zugesandt
                                            </p>*/}
                                            <p className="fw-light mb-4 ">
                                                <b>Super!</b> Dann kannst du dein Konto ganz einfach freischalten.
                                                <br /> Alles was du dafür noch brauchst ist eine <b>persönliche E-Mail Adresse</b> und deine <b>TAN</b>.
                                                <div style={{height:'1em'}}></div>
                                                <Button variant={"light"} onClick={() => {window.location="/aktivieren"}}><FontAwesomeIcon icon={faArrowRight} /> Zur Konto Aktivierung</Button>
                                            </p>
                                        </div>
                                        <hr />
                                        <Button variant={"light"} onClick={() => {window.location="/login"}}><FontAwesomeIcon icon={faArrowLeft} />Zurück zum Login</Button>
                                    </div>
                                    </Card.Body>
                                    <div style={{width:'100%',background:'#11578f', color:'white',textAlign:"center", padding:'0.5em',marginTop:'0em'}}>
                                        <span>my.ausbildung.cloud</span>
                                    </div>
                                </Card>
                                <div align={'left'}>
                                    <div style={{height:'1em'}}></div>
                                    <h4 className="fw-bold mb-3 mt-3 text-uppercase ">
                                        <b>Kein Nutzerkonto und keine TAN?</b>
                                    </h4>
                                    {/*<p className="fw-light mb-4 ">
                                                Ein Link zum Zurücksetzen Ihres Passworts wird Ihnen per E-Mail
                                                zugesandt
                                            </p>*/}
                                    <p className="fw-light mb-4 ">
                                        <b>Kein Problem!</b> Bitte kontaktiere deinen <b>Ausbilder</b>, um dein Nutzerkonto einzurichten. Halte bitte deine <b>E-mail-Adresse</b> und deine <b>Teilnehmernummer</b> bereit.
                                    </p>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default ForgotPassword;