import React, { Component } from 'react';
import {Link} from "react-router-dom";
import UserController from "../controller/UserController";
import {Button, Col, Dropdown, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBoxes, faCalendar, faCalendarDays,
    faCubes,
    faProjectDiagram, faSheetPlastic, faSignOut, faUser, faUserAstronaut,
    faUsers, faUserTie
} from "@fortawesome/free-solid-svg-icons";
import '../pages/pageComponents/PageLayout.css';
import logo from "../assets/bauabc_logo.PNG";
import LogoutButtonComponent from "./LogoutButtonComponent";

class AdminMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: UserController.getUser(),
            currentPathName: this.props.currentPathName
        }
    }

    render() {
        return (
            <div className={"user-menu"}>
                <Row>
                    <Col style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                        <Dropdown className={this.state.currentPathName === UserController.getMyProfileUrl()?'active':''}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <span><FontAwesomeIcon icon={faSheetPlastic} /> Arbeitsblätter</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClickCapture={() =>  {window.location = '/projects'}}>
                                    <span><FontAwesomeIcon icon={faProjectDiagram}/> Projekte</span>
                                </Dropdown.Item>
                                <Dropdown.Item onClickCapture={() =>  {window.location = '/templates'}}>
                                    <span><FontAwesomeIcon icon={faCubes}/> Templates</span>
                                </Dropdown.Item>
                                <Dropdown.Item onClickCapture={() =>  {window.location = '/worksheets'}}>
                                    <span><FontAwesomeIcon icon={faSheetPlastic}/> Arbeitsblatt-Templates</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown className={this.state.currentPathName === UserController.getMyProfileUrl()?'active':''}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <span><FontAwesomeIcon icon={faCalendar} /> Gruppen</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClickCapture={() =>  {window.location = '/courses'}}>
                                    <span><FontAwesomeIcon icon={faCalendarDays}/> Kurskalender</span>
                                </Dropdown.Item>
                                <Dropdown.Item onClickCapture={() =>  {window.location = '/instructors'}}>
                                    <span><FontAwesomeIcon icon={faUserTie}/> Ausbilder</span>
                                </Dropdown.Item>
                                <Dropdown.Item onClickCapture={() =>  {window.location = '/trainees'}}>
                                    <span><FontAwesomeIcon icon={faUsers}/> Auszubildende</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col xs={3} sm={5} md={7} style={{display:'flex',justifyContent:'right',alignItems:'center'}}>
                        <Dropdown className={this.state.currentPathName === UserController.getMyProfileUrl()?'active':''}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                         <span className={'userType'}>
                            {UserController.isTrainee() &&
                                <FontAwesomeIcon icon={faUser} />
                            }
                             {UserController.isInstructor() &&
                                 <FontAwesomeIcon icon={faUserTie} />
                             }
                             {UserController.isAdmin() &&
                                 <FontAwesomeIcon icon={faUserAstronaut} />
                             }
                             <span className={"menuUserLoginName"} style={{marginLeft:'0.5em'}}>{UserController.getUser().email}</span>
                         </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClickCapture={() =>  {
                                    window.location = UserController.getMyProfileUrl();
                                }}>
                                    <span>Mein Profil</span>
                                </Dropdown.Item>
                                <Dropdown.Item onClickCapture={() =>  UserController.logout()}>
                                    <span><FontAwesomeIcon icon={faSignOut}/> Ausloggen</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default AdminMenu;