import React, {Component} from 'react';


import {Badge, Button, Col, Container, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './SingleTraineePage.css'
import {
    faBoxes,
    faDiagramProject,
    faEnvelope,
    faExclamationTriangle,
    faHashtag,
    faHourglassEnd,
    faSheetPlastic,
    faTools,
    faUser,

} from "@fortawesome/free-solid-svg-icons";
import PageHeader from "../../includes/PageHeader";
import LoadingScreen from "../../includes/LoadingScreen";
import PageContent from "../../includes/PageContent";
import DateTimeUtil from "../../util/DateTimeUtil";
import CourseBadges from "../../components/badges/CourseBadges";
import CenteredMessage from "../../includes/CenteredMessage";
import he from "he";
import APIController from "../../controller/APIController";
import PradleAccordion from "../../components/PradleAccordion";
import _ from "lodash";
import TopicKachel from "../../components/TopicKachel";
class SingleInstructorPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            reload:true,
            id:props.params.id,
            trainee : null,
            profile : null,
        };
    }



    async reload(){
        try {
            this.setState({
                isFetching:true
            })
            const trainee = await APIController.getTraineeById(this.state.id);
            const profileResponse = await APIController.getTraineeProfile();
            trainee.connectedTopics = _.sortBy(trainee.connectedTopics, 'id').reverse();
            trainee.courses = _.sortBy(trainee.courses, 'start').reverse();



            this.setState({
                isFetching: false,
                reload:false,
                trainee : trainee,
                profile : profileResponse,
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                trainee : null,
                profile: null
            });
        }
    }


    async componentDidMount() {
        await this.reload();
    }


    getWorksheetsBagde(worksheets){
        if(worksheets.length == 1){
            return (
                <Badge bg={"dark"} style={{fontSize:'0.5em', marginLeft:'1em'}}><FontAwesomeIcon icon={faSheetPlastic} /> 1</Badge>
            )
        }else{
            return (
                <Badge bg={"dark"} style={{fontSize:'0.5em', marginLeft:'1em'}}><FontAwesomeIcon icon={faSheetPlastic} /> {worksheets.length}</Badge>
            )
        }
    }


    render() {

        if(!this.state.isFetching && this.state.trainee == null){
            return (
                    <>
                        <PageHeader title={"Auzubildenden Profil"} fontAwesomeIcon={faUser}/>
                        <PageContent>
                            <CenteredMessage>
                                <FontAwesomeIcon icon={faUser} />
                                Das Profil konnte nicht geladen werden
                            </CenteredMessage>
                        </PageContent>
                    </>
                )
        }

        return (
            <div>
                {this.state.isFetching? (<LoadingScreen />):(
                    <div className={"SingleTraineePage"}>
                        <PageContent>
                            <div>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faUser} /> Name</h5>
                                        <div>
                                            <span>{he.decode(this.state.trainee.firstname+" "+this.state.trainee.name)}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faHashtag} /> Teinehmer-Nr.</h5>
                                        <div>
                                            {this.state.trainee.externalId}
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faHashtag} /> Uniplus CRM-ID</h5>
                                        <div>
                                            {this.state.trainee._id}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faEnvelope} /> E-Mail</h5>
                                        <div>
                                            {this.state.trainee.email != ""?
                                                <span>{he.decode(this.state.trainee.email)}</span>
                                                :
                                                <span>Keine E-Mail Adresse</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faTools} /> Beruf</h5>
                                        <div>
                                            {this.state.trainee.professionId != ""?
                                                <span><FontAwesomeIcon icon={faTools} /> {this.state.trainee.professionId} | {he.decode(this.state.trainee.professionName)}</span>
                                                :
                                                <span style={{color:'red'}}><FontAwesomeIcon icon={faExclamationTriangle} /> Beruf vorhanden</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={6}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faHourglassEnd} /> Gültig bis</h5>
                                        <div>
                                            {!_.isEmpty(this.state.trainee.gueltigBis) &&
                                                <span>{
                                                    DateTimeUtil.parseIsoDateOfYear(this.state.trainee.gueltigBis) === "01.01.1960"?
                                                        "unbegrenzt":
                                                    DateTimeUtil.parseIsoDateOfYear(this.state.trainee.gueltigBis)}</span>
                                            }
                                            {_.isEmpty(this.state.trainee.gueltigBis) &&
                                                <span>Keine Angabe</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={12}>
                                    <div className={'profileBlock'}>
                                        <h5><FontAwesomeIcon icon={faBoxes} /> Gruppen <small>({this.state.trainee.courses.length})</small></h5>
                                        <div>
                                            <CourseBadges courses={this.state.trainee.courses} limit={50}></CourseBadges>
                                        </div>
                                    </div>
                                </Col>


                            </Row>
                                {this.state.trainee.courses.map((course,idx)=> {


                                    let worksheets = this.state.trainee.worksheets.filter((ws) => ws.courseId === course._id);

                                    if(worksheets.length == 0){
                                        return <></>
                                    }

                                    return(

                                    <PradleAccordion title={<div>
                                        <FontAwesomeIcon icon={faDiagramProject} /> {course.name}
                                        {this.getWorksheetsBagde(worksheets)}</div>} keyId={"topics_"+course._id} open={(idx===0)}>
                                        <h3>{DateTimeUtil.parseIsoDateOfYear(course.start)} - {DateTimeUtil.parseIsoDateOfYear(course.end)}</h3>
                                        <Row>
                                            {worksheets.map((ws,idx)=>{
                                                if(ws.state === APIController.WORKSHEET_STATES.DISCARDED_WORKING_SHEET){
                                                    /* Hide worksheets, which are discardedQ */
                                                    //TODO: Put this filter operation in API, instead of frontend
                                                    return <></>;
                                                }
                                                return (
                                                    <Col xs={12} sm={6} lg={4} xl={3}>
                                                        <TopicKachel
                                                            isAzubiKachel={true}
                                                            topicId={ws.topic}
                                                            topicName={(ws.taskName!=="" && ws.taskName!==null)?ws.taskName:"Projekt "+ws.topic}
                                                            courseId={ws.courseId}
                                                            worksheets={[ws]}
                                                        ></TopicKachel>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </PradleAccordion>
                                    );
                                })}
                            </div>
                            {/*
                            {(this.state.profile && this.state.profile.communicationData) &&
                                <div className={"mt-5"}>
                                    <Row>
                                        <Col xs={6} lg={6}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faPhone}/> Mobile</h5>
                                                <div>
                                                    {this.state.profile.communicationData.mobile && (this.state.profile.communicationData.mobile)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6} lg={6}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faPhoneAlt}/> Phone</h5>
                                                <div>
                                                    {this.state.profile.communicationData.phone && (this.state.profile.communicationData.phone)}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {(this.state.profile && this.state.profile.bankDetails) &&
                                <div className={"mt-5"}>
                                    <Row>
                                        <Col xs={12} lg={4}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faBank}/> Kreditinstitut</h5>
                                                <div>
                                                    {(this.state.profile.bankDetails.creditInstitution) && he.decode(this.state.profile.bankDetails.creditInstitution)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faVault}/> IBAN</h5>
                                                <div>
                                                    {this.state.profile.bankDetails.iban && (this.state.profile.bankDetails.iban)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faVault}/> BIC</h5>
                                                <div>
                                                    {this.state.profile.bankDetails.bic && (this.state.profile.bankDetails.bic)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={12}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faUserTie}/> Abweichender Kontoinhaber</h5>
                                                <div>
                                                    {this.state.profile.bankDetails.differentAccountHolder && he.decode(this.state.profile.bankDetails.differentAccountHolder)}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {(this.state.profile && this.state.profile.legalGuardian) &&
                                <div className={"mt-5"}>
                                    <Row>
                                        <Col xs={12} md={3} lg={3}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faHandshake}/> Anrede</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.salutation && (this.state.profile.legalGuardian.allSalutationOptions.filter((option) => option.key === this.state.profile.legalGuardian.salutation)[0].value)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={9} lg={9}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faPerson}/> Name</h5>
                                                <div>
                                                    {(this.state.profile.legalGuardian.firstname && this.state.profile.legalGuardian.name) && he.decode(this.state.profile.legalGuardian.firstname + " " + this.state.profile.legalGuardian.name)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faPhone}/> Telefon</h5>
                                                <div>
                                                    {(this.state.profile.legalGuardian.phone)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faEnvelope}/> E-Mail</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.email && (this.state.profile.legalGuardian.email)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={12} lg={12}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faRoad}/> Strasse</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.street && (this.state.profile.legalGuardian.street)}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={4} lg={4}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faLocationDot}/> PLZ</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.zip && this.state.profile.legalGuardian.zip}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={8} lg={8}>
                                            <div className={'profileBlock'}>
                                                <h5><FontAwesomeIcon icon={faTreeCity}/> Ort</h5>
                                                <div>
                                                    {this.state.profile.legalGuardian.city && he.decode(this.state.profile.legalGuardian.city)}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            }
                            */}
                        </PageContent>
                    </div>
                )}
            </div>
        );
    }
}


export default SingleInstructorPage;