import React from 'react';
import {v4 as uuidv4} from "uuid";
import TextComponent from "../input/TextComponent";
import TextInputFormField from "../formFields/TextInputFormField";
import { Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLink} from "@fortawesome/free-solid-svg-icons";

class ExternalLinkTextComponent extends TextComponent {
    constructor(props) {
        super(props);
        this.component = props.component;

        this.printMode = props.printMode || false;
        this.instructorMode = props.instructorMode || false;
        this.showEditorView = props.showEditorView || false;
        this.writeModeBasedOnPhase = props.writeModeBasedOnPhase || false;

        this._handleChange = this._handleChange.bind(this);
    }

    static create(){
        return {
                id: uuidv4(),
                type : "externalLink",
                description: "",
                title: "External-Links",
                data : {},
                config: {
                    externalUrl:"",
                    externalUrlTitle:""
                }
            }
    }

    _handleChange(id,value){
        this.component.config[id] = value;
        //this.updateComponent(this.component);
    }

    renderEditorView(){
        return (
            <div>
                <TextInputFormField
                    title={"URL / Externer Link"}
                    rows={1}
                    value={this.component.config.externalUrl}
                    onChange={(value) => this._handleChange('externalUrl',value)}
                    description={"Geben SIe hier die externe URL ein. Bspw. http://www.google.de"}
                />
                <TextInputFormField
                    title={"Titel des externen Links"}
                    rows={1}
                    value={this.component.config.externalUrlTitle}
                    onChange={(value) => this._handleChange('externalUrlTitle',value)}
                    description={"Geben Sie hier einen Namen des Links ein"}
                />
            </div>
        );
    }


    renderInstructorView(){
        if(this._isEmpty()){
            return <></>
        }
        let data = this._getData();
        return (
            <>

                <div style={{marginBottom:'0.5em'}}><span>{data.linkTitle}</span></div>

                <Button variant={"secondary"} title={data.externalUrl}  alt={data.externalUrl} style={{marginRight:'0.5em',marginTop:'0.5em'}} onClick={() => {
                    window.open(data.externalUrl,"_blank")
                }}>
                    <FontAwesomeIcon icon={faExternalLink} /> Link öffnen
                </Button>


            </>
        );
    }

    renderPrintView(){
        if(this._isEmpty()){
            return <></>
        }
        let data = this._getData();
        return (
            <>
                <div style={{marginBottom:'1em'}}><span>{data.linkTitle}</span></div>
                <span><a style={{fontWeight:"normal",fontStyle:'italic',color:'#11578f', textDecoration:'none'}}
                         title={data.linkTitle} href={data.externalUrl}><FontAwesomeIcon icon={faExternalLink} /> {data.externalUrl}</a></span>
            </>
        );
    }

    _getData(){
        let linkTitle = this.component.config.externalUrlTitle;
        if(linkTitle === undefined || linkTitle === ""){
            linkTitle = this.component.config.externalUrl;
        }
        return {
            linkTitle : linkTitle,
            externalUrl : this.component.config.externalUrl
        }
    }

    _isEmpty(){
        return (this.component.config.externalUrl === "" || this.component.config.externalUrl === undefined);
    }


    _render(){
        if(this.printMode){
            return <div>
                {this.renderPrintView()}
            </div>
        }
        else if(this.props.instructorMode){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInstructorView()}
            </div>
        } else if(this.props.showEditorView){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderEditorView()}
            </div>
        }else{
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInstructorView(this.instructorMode)}
            </div>
        }
    }

    render() {
        return (
            <div className={"externalLink"}>
                {this._render()}
            </div>
            )
    }
}
export default ExternalLinkTextComponent;