import React, { Component } from 'react';
import {Accordion, Alert, Badge, Button, Card, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBackwardStep, faCalendar, faCaretDown, faCaretUp, faClock, faFilePdf, faListNumeric,
    faTools, faUniversity, faUser, faUsers
} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "./input/ActionButton";
import './ProjectTaskDescription.css';
import _ from "lodash";
import he from "he";
import PhaseStateInfoBox from "./PhaseStateInfoBox.js";
import StatusBadge from "./badges/StatusBadge";
import EvaluationResultsTable from "./EvaluationResultsTable";
import {Link} from "react-router-dom";
import UserController from "../controller/UserController";
import PDFDownloadButton from "./PDFDownloadButton";
import APIController from "../controller/APIController";



class ProjectTaskDescription extends Component {

    constructor(props) {
        super(props);
        this.topicId = props.topicId;
        this.worksheet = props.worksheet;
        this.trainee = props.trainee;
        this.onWorksheetUpdated = props.onWorksheetUpdated;
        this.showActionButtons = props.showActionButtons!==undefined?props.showActionButtons:true;
        this.state = {
            openSections: {},
        };
    }

    toggleSection(sectionId){
        if (!this.state.openSections.hasOwnProperty(sectionId)) {
            this.state.openSections[sectionId] = false;
        }
        let openSections = this.state.openSections;
        openSections[sectionId] = !this.state.openSections[sectionId];
        this.setState({
            openSections
        });
    }

    findPhaseById(phaseId) {
        const result = this.worksheet.phases.find(obj => obj.id === phaseId);
        if(result === undefined){
            return "";
        }else{
            return result.title;
        }
    }

    getTraineeNameAndGroup(){
        let traineeName = he.decode(this.trainee.name) +", "+he.decode(this.trainee.firstname);
        if(UserController.isTrainee()){
            return <span>{traineeName} (TNr:&nbsp;{this.trainee.externalId})</span>
        }
        let name = <Link to={"/trainee/"+this.trainee._id+"/profile"}>
            <Badge bg={"dark"} style={{fontSize:'1em'}}>{traineeName}</Badge>
        </Link>;
        if(_.isEmpty(this.trainee)){
            return <span>Keine Angabe</span>
        }
        return <>
            <span>{name} TNr:&nbsp;{this.trainee.externalId}</span>
        </>
    }

    getTraineeCourse(){
        if(_.isEmpty(this.trainee)){
            return <span>Keine Angabe</span>
        }
        let tcourse = this.trainee.courses.filter((course) => course._id === this.worksheet.courseId)[0];
        if(UserController.isTrainee()){
            return <span>{he.decode(tcourse.name)}</span>
        }
        return <Link to={"/courses/"+this.worksheet.courseId+"/"+this.worksheet.topic}>
            <Badge style={{fontSize:'1em'}} bg={"dark"}><FontAwesomeIcon icon={faUsers} /> {he.decode(tcourse.name)}</Badge>
        </Link>;
    }

    render() {
        return (
            <div className={"ProjectTaskDescription"}>
                <div className={"taskDescription"}>
                    <Alert key={'warning'} variant={'warning'} style={{marginBottom:0}}>
                        <Accordion className={"sectionAccordion"} activeKey={this.state.openSections['auftrag_section']?"-1":"section-accordion-auftrag_section"}>
                            <Card>
                                <Card.Header className={"sectionAccordionCardheader"}>
                                    <Row>
                                        <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
                                            <div style={{textAlign:'center'}}>
                                                <h2 className={this.state.openSections['auftrag_section']?"open":"closed"}>
                                                    {this.worksheet.topic} - {_.isEmpty(this.worksheet.description.taskName)?"Projektauftrag "+this.worksheet.topic:he.decode(this.worksheet.description.taskName)}
                                                </h2>
                                                {!_.isEmpty(this.worksheet.description.taskDescription) &&
                                                    <div style={{textAlign:'center'}}>
                                                        <div style={{marginBottom:'1em'}}>
                                                            <span>{he.decode(this.worksheet.description.taskDescription)}</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                        <Col  xs={{ span: 6, order: 2 }} md={{ span: 3, order: 1 }}>
                                            <Button style={{marginRight:'1em'}} onClick={()=>{
                                                // eslint-disable-next-line no-restricted-globals
                                                history.back();
                                            }} variant="dark"><FontAwesomeIcon icon={faBackwardStep} /></Button>
                                        </Col>

                                        <Col  xs={{ span: 6, order: 3 }} md={{ span: 3, order: 3 }}>
                                            <div align={"right"} style={{paddingRight:'1em'}}>
                                                <ActionButton handler={() => {this.toggleSection('auftrag_section')}}>
                                                    {
                                                        !this.state.openSections['auftrag_section']?
                                                            <FontAwesomeIcon icon={faCaretUp} />:
                                                        <FontAwesomeIcon icon={faCaretDown} />
                                                    }
                                                </ActionButton>
                                                {this.worksheet.state === APIController.WORKSHEET_STATES.GRADED_WORKING_SHEET &&
                                                <PDFDownloadButton
                                                worksheetId={this.worksheet.id}
                                                courseId={this.worksheet.courseId}
                                                traineeId={this.worksheet.traineeId}
                                                topicId={this.worksheet.topic}
                                                />
                                                }
                                            </div>
                                        </Col>
                                    </Row>

                                </Card.Header>
                                <Accordion.Collapse eventKey={"section-accordion-"+'auftrag_section'}>
                                    <Card.Body className={"sectionAccordionCardBody"}>

                                        <div style={{textAlign:'center'}}>
                                            <div className={"workingSheetCurriculumDetailsWrapper"} style={{marginBottom:'1em'}}>
                                                <table className={"workingSheetCurriculumDetails"}>
                                                    <tr>
                                                        <td><FontAwesomeIcon icon={faUser} />&nbsp;Auszubildender</td>
                                                        <td>{this.getTraineeNameAndGroup()}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><FontAwesomeIcon icon={faCalendar} />&nbsp;Gruppe</td>
                                                        <td>{this.getTraineeCourse()}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><FontAwesomeIcon icon={faTools} />&nbsp;Beruf</td>
                                                        <td><span>{_.isEmpty(this.trainee.professionName)?"Keine Angabe":he.decode(this.trainee.professionName)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{whiteSpace:"nowrap"}}><FontAwesomeIcon icon={faUniversity} />&nbsp;Rahmenlehrplannr</td>
                                                        <td><span>{_.isEmpty(this.worksheet.description.curriculumId)?"Keine Angabe":he.decode(this.worksheet.description.curriculumId)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{whiteSpace:"nowrap"}}><FontAwesomeIcon icon={faListNumeric} />&nbsp;Lernfeldnummer</td>
                                                        <td><span>{_.isEmpty(this.worksheet.description.learningFieldId)?"Keine Angabe":he.decode(this.worksheet.description.learningFieldId)}</span></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <Row>
                                                <Col xs={12}>
                                                    <div style={{textAlign:'center'}}>
                                                        {this.worksheet != null &&
                                                            <>
                                                                <div>
                                                                    <h4 style={{marginBottom:'1em'}}>
                                                                        {PhaseStateInfoBox.renderWorksheetStateBubble(this.worksheet)}
                                                                    </h4>
                                                                </div>
                                                                {this.worksheet.state === StatusBadge.TYPES.OPEN_WORKING_SHEET.state &&
                                                                    <PhaseStateInfoBox worksheet={this.worksheet}></PhaseStateInfoBox>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                            {this.worksheet != null && this.worksheet.evaluation !== undefined && this.worksheet.state === StatusBadge.TYPES.GRADED_WORKING_SHEET.state &&
                                                <EvaluationResultsTable evaluation={this.worksheet.evaluation} />
                                            }
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Alert>
                </div>
            </div>
        )
    }
}


export default ProjectTaskDescription;