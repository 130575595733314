import React, { Component } from 'react';
import {Badge, Col, Row} from "react-bootstrap";
import he from "he";
import WorksheetListStateBadges from "./badges/WorksheetListStateBadges";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiagramProject} from "@fortawesome/free-solid-svg-icons";
import defaultImageUrl from "../assets/topic_images/1.jpg";
import WorksheetUtil from "../util/WorksheetUtil";

class TopicKachel extends Component {

    constructor(props) {
        super(props);
        this.topicId = props.topicId;
        this.topicName = props.topicName;
        this.worksheets = props.worksheets;
        this.isAzubiKachel = props.isAzubiKachel?props.isAzubiKachel:false;

        this.backgroundImageUrl = this._getTitleImageUrl([]);
        if(this.worksheets.filter(obj => obj.topic	===  this.topicId).length > 0){
            this.backgroundImageUrl = this._getTitleImageUrl(this.worksheets.filter(obj => obj.topic ==  this.topicId)[0]);
        }

        this.courseId = props.courseId;
    }

    _getTitleImageUrl(worksheet){
        if(worksheet === undefined){
            return defaultImageUrl;
        }
        let filesArray = [];
        if(worksheet.hasOwnProperty('files')){
            filesArray = worksheet.files;
        }
        let url =  WorksheetUtil.findPrimaryTitleImageUrl(filesArray);
        if(url == null){
            url = defaultImageUrl;
        }
        return url;
    }

    render() {

        let linkUrl = "/courses/"+this.courseId+"/"+this.topicId;
        if(this.isAzubiKachel){
            if(this.worksheets.filter(obj => obj.topic	===  this.topicId).length > 0){
                let firstWs = this.worksheets.filter(obj => obj.topic ==  this.topicId)[0];
                linkUrl = "/worksheets/"+ firstWs.id;
            }
        }

        return (
            <div className={"topicKachelOuter"}>
                <a href={linkUrl} style={{textDecoration:'none'}}>
                    <div className={"topicKachel"}  style={{background:'#11578f'}}>
                        <Row>
                            <Col xs={12}>
                                <div className={"topicSticker"}><span style={{fontWeight:'bold'}}>{he.decode(this.topicId)}</span></div>
                                <div className={"bgimage"} style={{height:'12em',
                                    backgroundSize: "cover",
                                    backgroundRepeat:'no-repeat',
                                    backgroundColor: 'white',
                                    backgroundPosition: "center center",
                                    backgroundImage:"url('"+this.backgroundImageUrl+"')"}}></div>
                            </Col>
                            <Col xs={12}>
                                <div className={"topicKachelInner"} style={{marginBottom:'0.5em'}}>
                                    <h3><span className={"topicId"} style={{borderRadius:0}} >{he.decode(this.topicName)}</span></h3>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} style={{display:'flex',justifyContent:'center',alignItems:'start'}}>
                                <div className={"topicKachelInner"}>
                                    {(this.worksheets.filter(obj => obj.topic	=== this.topicId).length > 0) &&
                                        <div className={"workingsheetBadges"}>
                                            <WorksheetListStateBadges worksheets={this.worksheets.filter(obj => obj.topic	=== this.topicId)} />
                                        </div>
                                    }
                                    {(this.worksheets.filter(obj => obj.topic	=== this.topicId).length === 0) &&
                                        <div className={"workingsheetBadges"}>
                                            <Badge style={{marginRight:'0.2em',marginBottom:'0.2em'}}>
                                                <span><FontAwesomeIcon icon={faDiagramProject} /> Keine Arbeitsblätter</span>
                                            </Badge>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </a>
            </div>
        )
    }
}


export default TopicKachel;