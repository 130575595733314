import {Component} from "react";
import UserController from "../controller/UserController";
import {ProgressBar} from "react-bootstrap";

class Progress extends Component {
    constructor(props) {
        super(props);
        this.variant = "info";
        this.userType = UserController.getUser().type
        this.progress = 0;
        this.fontSize = props.fontSize;
        this.bgColor = props.bgColor;
        this.noText = props.noText;

        this.state = {
            worksheets:props.worksheets
        };
    }


    countProgress(){
        let totalCount =  this.state.worksheets.length;
        let discardedCount = 0;
        let draftCount = 0;
        let openCount = 0;
        let submittedCount = 0;
        let gradedCount = 0;

        this.state.worksheets.forEach((w) => {
            if(w.state === "DRAFT_WORKING_SHEET"){
                draftCount += 1;
            }
            else if(w.state === "OPEN_WORKING_SHEET"){
                openCount += 1;
            }
            else if(w.state === "SUBMITTED_WORKING_SHEET"){
                submittedCount += 1;
            }
            else if(w.state === "GRADED_WORKING_SHEET"){
                gradedCount += 1;
            }
            else if(w.state === "DISCARDED_WORKING_SHEET"){
                discardedCount += 1;
            }
            else{
                console.error("INVALID STATE FOUND");
            }
        });

        if(this.userType === UserController.TYPES.TRAINEE){
            let divisor = totalCount - draftCount - discardedCount;
            let dividend =  gradedCount + submittedCount;
            this.progress = (dividend/divisor) * 100;
        }else{
            let divisor = totalCount - draftCount - discardedCount;
            let dividend =  gradedCount + openCount;
            this.progress = (dividend/divisor) * 100;
        }

        if(this.progress == 0){
            this.progress = 20;
        }else if(isNaN(this.progress)){
            this.progress = 100;
        }

        if(this.progress >= 75){
            this.variant = "success";
        }else if (this.progress >= 50){
            this.variant = "warning";
        }else if(this.progress >= 25){
            this.variant = "danger";
        }
    }

    render() {
        this.countProgress();
        let text = "";
        this.progress = Math.round(this.progress ) ;

        if(this.userType == UserController.TYPES.TRAINEE){
            if(this.progress == 100){
                text = "100% Top! Alles erledigt"
            }else{
                text = this.progress+"%!"
            }
        }else{
            if(this.progress == 100){
                text = "100% Top! Alles erledigt"
            }else{
                text = this.progress+"%"
            }
        }

        if(this.noText){
            text = "";
        }
        let bgColor = this.bgColor;
        if(this.progress <= 50){
            bgColor = "#dc3545";
        }else{
            bgColor = this.bgColor;
        }

        return <ProgressBar className={"myProgressBar"}
                            style={{borderRadius: "unset",height:'2em',background:bgColor,fontSize:this.fontSize}} animated
                            striped now={this.progress} variant={this.variant}
                            label={`${text}`}/>;
    }
}

export default Progress;