import React, { Component } from 'react';
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faFilePdf, faSpinner} from "@fortawesome/free-solid-svg-icons";
import APIController from "../controller/APIController";

class PDFDownloadButton extends Component {

    constructor(props) {
        super(props);

        this.isLoading = props.isLoading || false;
        this.worksheetId = props.worksheetId || false;
        this.courseId = props.courseId || false;
        this.topicId = props.topicId || false;
        this.traineeId = props.traineeId || false;

        this.state = {
            isLoading:false
        }
    }

    render() {
        return (
            <>
                {!this.state.isLoading ?
                    <Dropdown style={{display:"inline-block", marginLeft:'0.5em'}}>
                        <Dropdown.Toggle variant="warning">
                            <span className={"d-none d-sm-inline-block"}><FontAwesomeIcon icon={faFilePdf}/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClickCapture={() =>  { window.location = "/worksheets/"+this.worksheetId+"/pdf"}}>
                                <span><FontAwesomeIcon icon={faFilePdf}/> PDF-Vorschau</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClickCapture={async (e) => {
                                e.preventDefault();
                                this.setState(prevState => ({
                                    ...prevState,
                                    isLoading: true
                                }));
                                let pdfBlobFileSrc = await APIController.downloadPDFFile(
                                    this.worksheetId,
                                    this.traineeId,
                                    this.courseId,
                                    this.topicId,
                                );
                                this.setState(prevState => ({
                                    ...prevState,
                                    isLoading: false
                                }));
                            }}>
                                <span><FontAwesomeIcon icon={faDownload}/> PDF-Download</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>:
                    <Dropdown style={{display:"inline-block",marginLeft:'0.5em'}}>
                        <Dropdown.Toggle disabled={true} variant="warning">
                            <span className={"d-none d-sm-inline-block"}><FontAwesomeIcon icon={faFilePdf}/> <FontAwesomeIcon icon={faSpinner} spin /></span>
                        </Dropdown.Toggle>
                    </Dropdown>
                }

            </>
        )
    }
}


export default PDFDownloadButton;