import React, { Component } from 'react';
import {Alert, Col, Row} from "react-bootstrap";

import '../components/WorksheetPrintViewComponent.css';
import ComponentBlock from "./ComponentBlock";
import APIController from "../controller/APIController";
import ProjectTaskDescriptionPrintView from "./ProjectTaskDescriptionPrintView";
import logo from "../assets/bauabc_logo.PNG";
import FileUtil from "../util/FileUtil";


class WorksheetPrintViewComponent extends Component {

    constructor(props) {
        super(props);
        this.worksheetId = props.worksheetId;
        this.state = {
            isFetching: null,
            worksheet: null,
            message: null,
            error: false
        }
    }

    componentDidCatch(error, errorInfo) {
        // Fehlerinformationen für Logging oder Reporting erfassen
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
        this.setState()
        this.setState(prevState => ({
            ...prevState,
            error: errorInfo
        }));
    }


    async reload(){
        try {
            this.setState({
                isFetching:true
            });

            const worksheetId = encodeURIComponent(this.worksheetId);
            const worksheet = await APIController.getPublicWorksheetById(worksheetId);
            worksheet.files = await FileUtil.loadMediaFilesMeta(worksheet.files);

            this.setState({
                isFetching: false,
                worksheet: worksheet,
            });

        } catch (error) {
            this.setState({
                isFetching: false,
                message: error
            });
        }
    }

    componentDidMount() {
        this.reload();
    }

    renderTestComponents(component, index){
        return(
            <>
                <h5>Instructor-View</h5>
                <ComponentBlock
                    key={component.id}
                    onRemoveComponentClicked={() => {}}
                    component={component}
                    onComponentUpdate={() => {}}
                    componentIndex={index}
                    onCommentsUpdated={() => {}}

                    readOnlyMode={false}
                    printMode={false}
                    instructorMode={true}
                    showEditorView={false}
                    writeModeBasedOnPhase={false}
                />
                <h5>Printview</h5>
                <ComponentBlock
                    key={component.id}
                    onRemoveComponentClicked={() => {}}
                    component={component}
                    onComponentUpdate={() => {}}
                    componentIndex={index}
                    onCommentsUpdated={() => {}}

                    readOnlyMode={false}
                    printMode={true}
                    instructorMode={false}
                    showEditorView={false}
                    writeModeBasedOnPhase={false}
                />
                <h5>InputView (Write enabled)</h5>
                <ComponentBlock
                    key={component.id}
                    onRemoveComponentClicked={() => {}}
                    component={component}
                    onComponentUpdate={() => {}}
                    componentIndex={index}
                    onCommentsUpdated={() => {}}

                    readOnlyMode={false}
                    printMode={false}
                    instructorMode={false}
                    showEditorView={false}
                    writeModeBasedOnPhase={true}
                />
                <h5>InputView (Write disabled)</h5>
                <ComponentBlock
                    key={component.id}
                    onRemoveComponentClicked={() => {}}
                    component={component}
                    onComponentUpdate={() => {}}
                    componentIndex={index}
                    onCommentsUpdated={() => {}}

                    readOnlyMode={false}
                    printMode={false}
                    instructorMode={false}
                    showEditorView={false}
                    writeModeBasedOnPhase={false}
                />
            </>
        )
    }

    renderComponents(component, index){
       return(
           <>
               <ComponentBlock
                   key={component.id}
                   onRemoveComponentClicked={() => {}}
                   component={component}
                   onComponentUpdate={() => {}}
                   componentIndex={index}
                   onCommentsUpdated={() => {}}

                   readOnlyMode={false}
                   printMode={true}
                   instructorMode={false}
                   showEditorView={false}
                   writeModeBasedOnPhase={false}
               />
           </>
       )
    }

    render() {


        if(this.state.error){
            return (
                <div className="worksheetView WorksheetPrintViewComponent">
                    <h2>Arbeitsblatt konnte nicht geladen werden</h2>
                    <Alert variant={"danger"}>
                        {JSON.stringify(this.state.error)}
                    </Alert>
                </div>
            );
        }

        if(this.state.isFetching){
            return (
                <span>lade: {this.worksheetId}</span>
            );
        }else if(this.state.worksheet === null){
            return (
                <div className="worksheetView WorksheetPrintViewComponent">
                    <span>{JSON.stringify(this.state.message)}</span>
                </div>
            );
        }
            console.log("Start render: WorksheetPrintViewComponent");
            return (
                <>
                    <div className="page">
                        <div style={{padding:'0.5em 0em'}}>
                            <h5 className="text-uppercase"><img src={logo} style={{height: "30px",marginRight:'0.5em',marginLeft:'0.5em'}} className="App-logo" alt="logo"/> <b>Digitales Arbeitsblatt</b></h5>
                        </div>
                        <div className="content">
                            <div className="worksheetView WorksheetPrintViewComponent">
                                <div>
                                    <ProjectTaskDescriptionPrintView
                                        worksheet={this.state.worksheet}
                                        trainee={this.state.worksheet.trainee}
                                    />
                                </div>
                                <Row>
                                    <Col xs={12} sm={12} lg={12}>
                                        <div className={"my-worksheet-print"}>
                                            <div className={"sections-print"}>


                                                {this.state.worksheet.files.length > 0 &&
                                                    <div className={"section-print-wrapper"}>
                                                        <div className={"section-print-header"}>
                                                            <Row>
                                                                <h2>Projektzeichnungen / Grafiken</h2>
                                                            </Row>
                                                        </div>
                                                        <div className={"section-print"} key={"sec_0"}>
                                                            <div className={"components-print"}>
                                                                <Row>
                                                                    <Col xs={12}>
                                                                        {FileUtil.renderTitleImageFiles(this.state.worksheet.files)}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {this.state.worksheet.sections.map((section, sectionIndex) => {
                                                    return (!section.hide) &&
                                                        <div className={"section-print-wrapper"}>
                                                            <div className={"section-print-header"}>
                                                                <Row>
                                                                    <h2>{section.title}</h2>
                                                                </Row>
                                                            </div>
                                                            <div className={"section-print"} key={sectionIndex}>
                                                                <div className={"components-print"}>
                                                                    <Row>
                                                                        {section.components.map((component, index) => {
                                                                            return (
                                                                                <Col key={index}
                                                                                     xs={component.config.size ? component.config.size : 12}>
                                                                                    {!component.hide &&
                                                                                        this.renderComponents(component, index)
                                                                                        //this.renderTestComponents(component, index)
                                                                                    }
                                                                                </Col>
                                                                            )
                                                                        })}
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        ;
                                                })}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="footer">

                        </div>
                        <div className="disclaimer">
                            <span>my.ausbildung.cloud</span>
                        </div>
                    </div>
                </>
            )
    }
}


export default WorksheetPrintViewComponent;