import React from 'react';
import {v4 as uuidv4} from "uuid";
import {Alert, Col, Row} from "react-bootstrap";
import NumberInputFormField from "../formFields/NumberInputFormField";
import InputComponent from "./InputComponent";
import './TextareaInput.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle, faWarning} from "@fortawesome/free-solid-svg-icons";

class TextareaInput extends InputComponent {
    constructor(props) {
        super(props);
        this._handleChange = this._handleChange.bind(this);
        this.updateComponent = props.updateComponent;

        this.printMode = props.printMode || false;
        this.instructorMode = props.instructorMode || false;
        this.showEditorView = props.showEditorView || false;
        this.writeModeBasedOnPhase = props.writeModeBasedOnPhase || false;

        this.uuid = uuidv4();
        this.state = {
            component: props.component
        };
    }

    _handleChange(id,checked) {
        this.state.component.config[id] = checked;
        //this.updateComponent(this.component);
    };

    _handleDataChange(id,value) {
        this.state.component.data[id] = value.target.value;
        this.setState({
            component: this.state.component
        });
        this.updateComponent(this.component);
    };
    static create(){
        return {
                id: uuidv4(),
                type : "textInput",
                title: "Text-Eingabe",
                data : {
                    text: "",
                },
                config: {
                    rows: 5,
                    commentFunctionChecked : true,
                    evaluationChecked: true,
                    description: ""
                }
            }
    }

    // We need to spread the previous state and change the one we're targeting, so other data cannot be lost.
    renderEditorView(){
        return (
            <div>
                <Row>
                    <Col xs={12} md={6}>
                        <NumberInputFormField
                            title={"Anzahl an Zeilen"}
                            description={"Wie groß soll der Textbereich sein?"}
                            maxValue={30}
                            value={this.state.component.config.rows}
                            suffix={"Zeilen"}
                            onChange={(value) => this._handleChange('rows',value)}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    renderInputView(){
        return (
            <div className={this.state.component.type}>
                <textarea value={this.state.component.data.text}
                          disabled={!this.writeModeBasedOnPhase}
                          onChange={(value) => this._handleDataChange('text',value)}
                          className={"form-control"} style={{width:'100%'}} rows={this.state.component.config.rows}>
                </textarea>
            </div>
        );
    }


    renderPrintView(){

        if(this.state.component.data.text === "" || this.state.component.data.text === null){
            return (
                <Alert variant={"warning"} style={{marginBottom:0}}>
                    <span style={{fontWeight:"bold"}}><FontAwesomeIcon icon={faWarning} /> Keine Angabe</span><br />
                </Alert>
            );
        }else{
            return (
                <div className={"textvalue"}>{this.state.component.data.text}</div>
            );
        }
    }

    _render() {
        if(this.props.printMode){
            return <div>
                {this.renderPrintView()}
            </div>
        }
        else if(this.props.instructorMode){
            return <div>
                {this.renderPrintView()}
            </div>
        } else if(this.props.showEditorView){
            return <div>
                {this.renderEditorView()}
            </div>
        }else{
            return <div>
                {this.renderInputView(this.instructorMode)}
            </div>
        }
    }

    render() {
        return (
            <div className={"textInput"}>
                {this._render()}
            </div>
        )
    }
}
export default TextareaInput;