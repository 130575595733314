import React, {Component, useEffect, useState} from "react";
import UserController from "../../controller/UserController";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import logo from "../../assets/bauabc_logo.PNG";
import * as formik from "formik";
import * as yup from "yup";
import APIController from "../../controller/APIController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHourglassStart} from "@fortawesome/free-solid-svg-icons";
import config from "../../config";


const ResetPasswordFormComponent = (props) => {

    const { Formik } = formik;
    const schema = yup.object().shape({
        password: yup.string()
            .required("Passwort wird benötigt")
            .matches(
                config.REGEX_FOR_PASSWORD,
                config.VALIDATION_MESSAGE_ON_PASSWORD_REGEX
            ),
        confirmPassword : yup.string()
            .required("Dieses Feld ist erforderlich")
            .oneOf([yup.ref('password'), null], "Die Passwörter müssen übereinstimmen")
    });

    const [initialValues, setInitialValues] = React.useState({ password: "", confirmPassword: ""});
    const [apiResponseMessage, setApiResponseMessage] = useState("")
    const [inProgress, setInProgress] = useState(false);
    const [alertType, setAlertType] = useState("alert-danger");

    useEffect(() => {
        const errorMessageCleaner = setInterval(() => {
            if(apiResponseMessage !== ""){
                setApiResponseMessage("");
                setAlertType("alert-danger");
            }
        }, config.API_MESSSAGE_SHOW_TIMER);
        return () => clearInterval(errorMessageCleaner);
    }, [apiResponseMessage, alertType]);


    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={ async (values, { resetForm }) => {
                try {

                    setApiResponseMessage("");
                    setInProgress(true);

                    let isAccountPasswordSet = await APIController.resetPassword(values.password, props.nuance);
                    if(isAccountPasswordSet.status){
                        setAlertType("alert-success");
                        resetForm();
                        UserController.loginLocalUser(
                            isAccountPasswordSet.loginSession.sessionToken,
                            isAccountPasswordSet.loginSession.type,
                            isAccountPasswordSet.loginSession.email,
                            isAccountPasswordSet.loginSession.id
                        );
                        window.location = UserController.getMyCoursesUrl();
                    }
                }
                catch (error){
                    resetForm();
                    setInProgress(false);
                    setApiResponseMessage(error.message);
                }
            }}>
            {({ handleSubmit, setFieldValue, values, handleBlur, touched, errors}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            Lege ein neues Passwort fest
                        </Form.Label>
                        <Form.Control type="password" placeholder="Bestätige das Passwort" id="password" value={values.password}
                                      onBlur={handleBlur}
                                      isInvalid={touched.password && !!errors.password}
                                      onChange={(e) => setFieldValue( "password",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">
                            Bestätige das Passwort
                        </Form.Label>
                        <Form.Control type="password" placeholder="Passwort eingeben" id="confirmPassword" value={values.confirmPassword}
                                      onBlur={handleBlur}
                                      isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                                      onChange={(e) => setFieldValue( "confirmPassword",e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" >
                            {errors.confirmPassword}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {apiResponseMessage &&
                        <div className={"mt-3 mb-3 alert " + alertType } dangerouslySetInnerHTML={{__html: apiResponseMessage}}/>
                    }
                    <div className="d-grid">
                        <div align={'center'}>
                            <div style={{display: 'inline-block', marginRight: '0.5em'}}>
                                {!inProgress ?
                                    <Button
                                        type="submit"
                                        variant="outline-secondary">
                                        Passwort festlegen
                                    </Button> :
                                    <span>Bitte warten <FontAwesomeIcon icon={faHourglassStart}/></span>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

class RestPassword extends Component{
    constructor(props){
        super(props);
        if(UserController.isLoggedIn()){

            window.location = UserController.getMyCoursesUrl();
        }
        this.params = props.params;
        this.nuance = this.params.get('nuance') !== null ? this.params.get('nuance') : "";

        if(!this.nuance){
            window.location = "/login";
        }

        this.state = {}
    }

    render() {
        return(
            <>
                <div className="Forgot-password">
                    <Container>
                        <Row className="vh-100 d-flex justify-content-center align-items-center">
                            <Col md={8} lg={6} xs={12}>
                                <Card className="shadow">
                                    <Card.Body>
                                        <div align={'center'}>
                                            <img src={logo} style={{height: "80px"}} className="App-logo" alt="logo"/>
                                        </div>
                                        <div className="mb-3 mt-md-4">
                                            <div align={'center'}>
                                                <h4 className="fw-bold mb-3 text-uppercase ">
                                                    <b>Neues Passwort festlegen</b>
                                                </h4>
                                            </div>
                                            <div className="mb-3 mt-md-4">
                                                <ResetPasswordFormComponent state={this.state} nuance={this.nuance}/>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default RestPassword;