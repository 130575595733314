import React, { Component } from 'react';
import {Link} from "react-router-dom";
import UserController from "../controller/UserController";
import {Col, Dropdown, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendar, faCube, faCubes,
    faSheetPlastic, faSignOut, faTools, faUser, faUserAstronaut,
    faUserTie
} from "@fortawesome/free-solid-svg-icons";
import './UserMenu.css';
import logo from "../assets/bauabc_logo.PNG";
import APIController from "../controller/APIController";


class UserMenu extends Component {

    constructor(props) {
        super(props);


        this.state = {
            fetching:true
        }

    }
    _getStammgewerkNummerByProfessionId(professionId){
        if(professionId === undefined || professionId.toString().length !== 4){
            return null;
        }
        return professionId.toString()[1]+professionId.toString()[2];
    }
    async componentDidMount() {
        let allTopics = [];
        if(UserController.isInstructor()){
            allTopics = await APIController.getAllTopics();
            allTopics = allTopics.filter(t=>!t._id.endsWith("1"));
        }
        this.setState({
            fetching:false,
            user: UserController.getUser(),
            currentPathName: this.props.currentPathName,
            allTopics :allTopics
        });
    }
    render() {
        let user = UserController.getUser();

        return (
            <div key={"userMenu_"+user.id}>
                <Row><Col xs={12} style={{background:'#fff',padding:'0.5em'}}>
                    <a style={{textDecoration:'none'}} href={UserController.getMyWorksheetUrl()}>
                        <h5 className="text-uppercase"><img src={logo} style={{height: "30px",marginRight:'0.5em',marginLeft:'1em'}} className="App-logo" alt="logo"/> <b>Digitales Arbeitsblatt</b></h5>
                    </a>
                </Col>
                </Row>
                <div className={"user-menu"}>
                    <Row>
                        <Col xs={10} sm={10} md={10} style={{display:'flex',justifyContent:'left',alignItems:'center'}}>


                            {UserController.isInstructor() &&
                                <Link className={this.state.currentPathName === UserController.getMyCoursesUrl()?'active':''} to={UserController.getMyCoursesUrl()}>
                                    <span><FontAwesomeIcon icon={faCalendar} /> Kurse</span>
                                </Link>
                            }
                            {UserController.isTrainee() &&
                                <Link className={this.state.currentPathName === UserController.getMyWorksheetUrl()? 'active':''} to={UserController.getMyWorksheetUrl()}>
                                    <span><FontAwesomeIcon icon={faSheetPlastic} /> Meine Projekte</span>
                                </Link>
                            }


                            {(!this.state.fetching && UserController.isInstructor()) &&
                                <>
                                    <Dropdown  className={(this.state.currentPathName.startsWith('/topics'))?'active':''}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <span className={"d-none d-sm-inline-block"}><FontAwesomeIcon icon={faSheetPlastic}/> Arbeitsblätter</span>
                                            <span className={"d-sm-inline-block d-sm-none"}><FontAwesomeIcon icon={faSheetPlastic}/> Arbeitsblätter</span>
                                        </Dropdown.Toggle>
                                        <div>
                                            <Dropdown.Menu style={{height:'15em',overflowY:'scroll'}}>
                                                <Dropdown.Item key={"dpi_topics"} onClickCapture={() =>  {window.location = '/topics'}}>
                                                    <span><FontAwesomeIcon icon={faSheetPlastic}/> Alle Arbeitsblätter</span>
                                                </Dropdown.Item>
                                                {this.state.allTopics.map((topicGroupedByProfession,idx)=> {

                                                    let stammdatengewerk = this._getStammgewerkNummerByProfessionId(topicGroupedByProfession._id);
                                                    let url = "";
                                                    if(stammdatengewerk != null){
                                                        url = '/topics/'+topicGroupedByProfession._id+"/*-"+stammdatengewerk+"-*"
                                                    }else{
                                                        url = '/topics/'+topicGroupedByProfession._id
                                                    }

                                                    return(
                                                        <Dropdown.Item key={"dpi_topics"+url} onClickCapture={() =>  {window.location = url;}}>
                                                            <span><FontAwesomeIcon icon={faTools}/>({topicGroupedByProfession._id}) {topicGroupedByProfession.professionName}</span>
                                                        </Dropdown.Item>
                                                    )
                                                })}
                                            </Dropdown.Menu>
                                        </div>
                                    </Dropdown>
                                </>
                            }
                            {((!this.state.fetching && UserController.isInstructorAdmin())) &&
                                <>
                                    <Dropdown  className={(this.state.currentPathName.startsWith('/templates'))?'active':''}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <span className={"d-none d-sm-inline-block"}><FontAwesomeIcon icon={faCubes}/> Templates</span>
                                            <span className={"d-sm-inline-block d-sm-none"}><FontAwesomeIcon icon={faCubes}/></span>
                                        </Dropdown.Toggle>
                                        <div>
                                            <Dropdown.Menu style={{height:'15em',overflowY:'scroll'}}>
                                                <Dropdown.Item key={"dpi_topics_templates"} onClickCapture={() =>  {window.location = '/templates'}}>
                                                    <span><FontAwesomeIcon icon={faCubes}/> Alle Templates</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item key={"dpi_topics_templates_all"} onClickCapture={() =>  {window.location = '/templates/-1'}}>
                                                    <span><FontAwesomeIcon icon={faCube}/> Gewerksübergreifend</span>
                                                </Dropdown.Item>
                                                {this.state.allTopics.map((topicGroupedByProfession,idx)=> {
                                                    return(
                                                        <Dropdown.Item key={"dpi_topics_t"+topicGroupedByProfession._id} onClickCapture={() =>  {window.location = '/templates/'+topicGroupedByProfession._id}}>
                                                            <span><FontAwesomeIcon icon={faTools}/>({topicGroupedByProfession._id}) {topicGroupedByProfession.professionName}</span>
                                                        </Dropdown.Item>
                                                    )
                                                })}
                                            </Dropdown.Menu>
                                        </div>
                                    </Dropdown>
                                </>

                            }
                            {this.state.fetching &&
                                <Link to={'/topics'}>
                                    <span className={"d-none d-sm-inline-block"}><FontAwesomeIcon icon={faSheetPlastic}/> Arbeitsblätter</span>
                                    <span className={"d-sm-inline-block d-sm-none"}><FontAwesomeIcon icon={faSheetPlastic}/></span>
                                </Link>
                            }

                        </Col>
                        <Col xs={2} sm={2} md={2} style={{display:'flex',justifyContent:'right',alignItems:'center'}}>
                            <Dropdown className={this.state.currentPathName === UserController.getMyProfileUrl()?'active':''}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                         <span className={'userType'}>
                            {UserController.isTrainee() &&
                                <FontAwesomeIcon icon={faUser} />
                            }
                             {UserController.isInstructor() &&
                                 <FontAwesomeIcon icon={faUserTie} />
                             }
                             {UserController.isAdmin() &&
                                 <FontAwesomeIcon icon={faUserAstronaut} />
                             }
                             <span className={"menuUserLoginName"} style={{marginLeft:'0.5em'}}>{UserController.getUser().email}</span>
                         </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClickCapture={() =>  {
                                        window.location = UserController.getMyProfileUrl();
                                    }}>
                                        <span>Mein Account</span>
                                    </Dropdown.Item>
                                    {UserController.isTrainee() &&
                                        <>

                                            <Dropdown.Item onClickCapture={() =>  {
                                                window.location = UserController.getMyProfileUrl() + "/update";
                                            }}>
                                                <span>Meine Profildaten</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClickCapture={() =>  {
                                                window.location = UserController.getMyProfileUrl() + "/update-password";
                                            }}>
                                                <span>Kennwort ändern</span>
                                            </Dropdown.Item>

                                        </>
                                    }
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClickCapture={() =>  UserController.logout()}>
                                        <span><FontAwesomeIcon icon={faSignOut}/> Ausloggen</span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
            </div>

        )
    }
}


export default UserMenu;