import React, {createRef} from 'react';
import './TableInput.css';
import {v4 as uuidv4} from "uuid";
import {Alert, Badge, Button, Col, Row} from "react-bootstrap";
import NumberInputFormField from "../formFields/NumberInputFormField";
import Dropzone from "react-dropzone";

import './FileUploadInput.css';
import MultiSelectInputFormField from "../formFields/MultiSelectInputFormField";
import InputComponent from "./InputComponent";
import TextInputFormField from "../formFields/TextInputFormField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faExternalLink,
    faTrashCan,
    faUpload,
    faUserTie,
    faWarning
} from "@fortawesome/free-solid-svg-icons";
import config from "../../config";
import APIController from "../../controller/APIController";
import FileUtil from "../../util/FileUtil";
import LoadingScreen from "../../includes/LoadingScreen";

const allAcceptableFileTypes = [
    {
        id: uuidv4(),
        name: "PDF"
    },
    {
        id: uuidv4(),
        name: "PNG"
    },
    {
        id: uuidv4(),
        name: "Powerpoint"
    },
    {
        id: uuidv4(),
        name: "Excel"
    },
    {
        id: uuidv4(),
        name: "JPG"
    },
    {
        id: uuidv4(),
        name: "MP4"
    }
]

class FileUploadInput extends InputComponent {
    constructor(props) {
        super(props);
        this.component = props.component;
        this.dropzoneRef = createRef();
        this._handleChange = this._handleChange.bind(this);
        this.updateComponent = props.updateComponent;
        this.onDrop = this.onDrop.bind(this);

        this.printMode = props.printMode || false;
        this.instructorMode = props.instructorMode || false;
        this.showEditorView = props.showEditorView || false;
        this.writeModeBasedOnPhase = props.writeModeBasedOnPhase || false;

        this.state = {
            fetching: true,
            mediaFilesMeta: {}
        }
    }

    async reload() {
        this.setState(prevState => ({
            ...prevState,
            fetching: true,
        }));
        let filesWithMeta = await FileUtil.loadMediaFilesMeta(this.component.config.files);
        this.setState(prevState => ({
            ...prevState,
            fetching: false,
            filesWithMeta: filesWithMeta
        }));
    }

    async componentDidMount() {
        await this.reload();
    }

    openDialog(){
        // Note that the ref is set async,
        // so it might be null at some point
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open()
        }
    };

    static create(){
        return {
            id: uuidv4(),
            type : "fileUpload",
            title: "Datei Upload",
            data : {

            },
            config: {
                selectedAcceptedFiletypes: [
                    allAcceptableFileTypes[0],
                    allAcceptableFileTypes[4]
                ],
                minFilesCount : 1,
                maxFilesCount : 1,
                maxFilesSize : 10,
                files: []
            }

        };
    }

    async _handleChange(id, value) {
        this.component.config[id] = value;
        await this.reload();
        this.updateComponent(this.component);
    };

    renderEditorView(){
        return (
            <div className={this.component.type}>
                <div>
                    <Row>
                        <Col xs={12} md={6}>
                            <MultiSelectInputFormField
                                title={"Erlaubte Dateitypen"}
                                description={"Welche Datei-Formate sind erlaubt?"}
                                options={allAcceptableFileTypes}
                                selectedValues={this.component.config.selectedAcceptedFiletypes}
                                onChange={(selectedValues) => {
                                    this.component.config.selectedAcceptedFiletypes = selectedValues;
                                    this.updateComponent(this.component);
                                }}
                                displayValue="name" // Property name to display in the dropdown options
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <NumberInputFormField
                                title={"Maximale Dateigröße"}
                                description={"Speichergröße, die eine Datei maximal haben darf"}
                                maxValue={100}
                                steps={10}
                                suffix={"MB"}
                                value={this.component.config.maxFilesSize}
                                onChange={async (value) => await this._handleChange('maxFilesSize', value)}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <NumberInputFormField
                                title={"Minimale Anzahl"}
                                description={"Anzahl an Dateien, die mindestens eingefügt werden müssen"}
                                maxValue={10}
                                suffix={""}
                                value={this.component.config.minFilesCount}
                                onChange={async (value) => await this._handleChange('minFilesCount', value)}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <NumberInputFormField
                                title={"Maximale Anzahl"}
                                description={"Anzahl an Dateien, die maximal eingefügt werden dürfen"}
                                maxValue={10}
                                suffix={""}
                                value={this.component.config.maxFilesCount}
                                onChange={async (value) => await this._handleChange('maxFilesCount', value)}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

        );
    }

    async onDrop(acceptedFiles){
        this.setState(prevState => ({
            ...prevState,
            fetching: true,
        }));
        var self = this;
        for (const file of acceptedFiles) {
            let maxAllowedSize = this.component.config.maxFilesSize * 1024 * 1024;
            let fileResponse = await APIController.uploadFile(file,maxAllowedSize);
            if(fileResponse != null && fileResponse.url !== ""){
                self.component.config['files'].push(
                    fileResponse
                )
                self.updateComponent(self.component);
                await this.reload();
            }
        }
    }

    _updateFileName(component,fileId,newFileDescription){
        component.config.files.map( (file, idx) => {
            if(file.id == fileId){
                component.config.files[idx].description = newFileDescription;
            }
        });
    }

    async _removeFileById(component,fileId){
        component.config.files.map( (file, idx) => {
            if(file.id == fileId){
                fetch(
                    `${config.API_HOST_URL}/files/${file.name}`,
                    {
                        method: "DELETE",
                    }
                ).then(function(response) {
                    return response.json();
                })
                component.config.files.splice(idx, 1);
            }
        });
        await this.reload();
    }

    renderInputView(){
        return (
            <div className={this.component.type}>

                <Dropzone onDrop={this.onDrop} ref={this.dropzoneRef} noClick noKeyboard disabled={!this.writeModeBasedOnPhase}>
                    {({getRootProps, getInputProps, acceptedFiles}) => {
                        return (
                            <div>
                                {(this.component.config['files'].length < this.component.config.minFilesCount) &&
                                    <div>
                                        <strong>Erst {this.component.config['files'].length}/{this.component.config.minFilesCount} Dateien hochgeladen!</strong>
                                    </div>
                                }
                                {(!(this.component.config['files'].length >= this.component.config.maxFilesCount)
                                ||
                                    this.component.config['files'].length < this.component.config.minFilesCount) && this.writeModeBasedOnPhase &&
                                <div style={{marginBottom:'1em'}}>
                                    <div {...getRootProps({className: 'dropzone ' + (!this.writeModeBasedOnPhase ? 'disabled' : '')})}>
                                        <input {...getInputProps()} />
                                        <p style={{fontStyle:"italic", color:'#0a375c', fontWeight:'bold'}}>Drag & Drop Dateien hier</p>
                                        <Button
                                            type="button"
                                            variant={"light"}
                                            disabled={!this.writeModeBasedOnPhase}
                                            onClick={() => this.openDialog()}
                                        >
                                            <FontAwesomeIcon icon={faUpload} /> Datei hochladen
                                        </Button>
                                    </div>
                                </div>
                                }
                                {this.state.filesWithMeta.map(file => (
                                        <div className={"fileuploadFileRow"}>
                                            <Row key={file.id}>
                                                <Col className={'mb-1'} xs={12} lg={3}>
                                                    <div>{FileUtil.renderFile(file)}</div>
                                                </Col>
                                                <Col className={'mb-1'} xs={12} lg={9}>
                                                    <div style={{maxWidth:'25em',marginBottom:'0.5em'}}>
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1" style={{fontStyle:'italic'}}><small>Titel / Beschreibung</small></label>
                                                            <TextInputFormField
                                                                title={""}
                                                                disabled={!this.writeModeBasedOnPhase}
                                                                rows={1}
                                                                placeholder={"..."}
                                                                description={""}
                                                                onChange={(value) => {
                                                                    this._updateFileName(this.component,file.id,value);
                                                                    this.updateComponent(this.component);
                                                                }}
                                                                value={file.description}
                                                            />
                                                        </div>

                                                    </div>
                                                    <Button disabled={!this.writeModeBasedOnPhase} variant={"light"} onClick={async () => {
                                                        if (window.confirm("Möchten Sie die Datei wirklich löschen?")) {
                                                            await this._removeFileById(this.component, file.id);
                                                            this.updateComponent(this.component);
                                                        }
                                                    }}>
                                                        <FontAwesomeIcon icon={faTrashCan} /> Löschen
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                            </div>
                        );
                    }}
                </Dropzone>
                <div className={"fileRulesMetaInfos"}>
                    <div className={"fileRulesMetaInfo"}>
                        <span style={{marginRight:'0.5em'}}>Erlaubte Datei-Typen</span>
                        {this.component.config.selectedAcceptedFiletypes.map((option, i) => {
                            return (
                                <Badge style={{marginRight:'0.5em'}} bg="dark">{option.name}</Badge>
                            )
                        })}
                    </div>
                    <div className={"fileRulesMetaInfo"}>
                        <span style={{marginRight:'0.5em'}}>Maximale Dateigröße</span>
                        <Badge style={{marginRight:'0.5em'}} bg="danger">{this.component.config.maxFilesSize}MB</Badge>
                    </div>
                    <div className={"fileRulesMetaInfo"}>
                        <span style={{marginRight:'0.5em'}}>Minimale Anzahl Dateien</span>
                        <Badge style={{marginRight:'0.5em'}} bg="dark">{this.component.config.minFilesCount}</Badge>
                    </div>
                    <div className={"fileRulesMetaInfo"}>
                        <span style={{marginRight:'0.5em'}}>Maximale Anzahl Dateien</span>
                        <Badge style={{marginRight:'0.5em'}} bg="danger">{this.component.config.maxFilesCount}</Badge>
                    </div>
                </div>
            </div>
        );
    }


    _allFilesAreImages(files){
        let allFilesAreIMages = true;
        files.map((file) => {
            if(!FileUtil.canRenderInImgTag(file.type)){
                allFilesAreIMages=false;
            }
        });
        return allFilesAreIMages;
    }

    renderInstructorView(){
        return (
            <>
                <div className={"imageView"}>
                    {this.component.config['files'].length === 0 &&
                        <Alert variant={"warning"}><FontAwesomeIcon icon={faWarning} /> <span style={{fontStyle:'italic'}}>Keine Dateien hochgeladen</span></Alert>
                    }
                    {(this.component.config['files'].length > 0 && !this._allFilesAreImages(this.component.config['files'])) &&
                        <div>
                            <Alert style={{display:'inline-block'}} variant="danger"><FontAwesomeIcon icon={faWarning} /> Hochgeladene Dateien werden nicht automatisch auf Viren geprüft</Alert>
                        </div>
                    }
                    {FileUtil.renderFiles(this.state.filesWithMeta, false,false)}
                </div>
                <div style={{clear:'both'}}></div>
            </>
        );
    }

    renderPrintView(){
        return (
            <>
                <div className={"imageView"}>
                    {this.component.config['files'].length === 0 &&
                        <Alert variant={"warning"}><FontAwesomeIcon icon={faWarning} /> <span style={{fontStyle:'italic'}}>Keine Dateien hochgeladen</span></Alert>
                    }
                    {FileUtil.renderFiles(this.state.filesWithMeta, false,false)}
                </div>
                <div style={{clear:'both'}}></div>
            </>
        );
    }




    renderFileView(file, printView=false, smallSize = false){
        return (
            <div key={file.id} className={smallSize?"imageWrapperOuter small":"imageWrapperOuter"}>
                <div>
                    {FileUtil.canRenderInImgTag(file.type) ?
                        <div style={{marginBottom:'0.5em'}}>
                            <div key={file.id} className={"imageWrapper"}>
                                <img src={file.url} alt={file.description}/>
                            </div>
                        </div>
                        :
                        <>
                            <div key={file.id} className={"imageWrapper"}>
                                {printView?
                                <a style={{textDecoration:'none'}} href={file.url}><FontAwesomeIcon icon={faExternalLink} /> {file.url}</a>
                                    :
                                    <Button variant={"light"} onClick={()=>{
                                        window.open(file.url)
                                    }
                                    }><FontAwesomeIcon icon={faDownload}/> Datei downloaden</Button>
                                }
                            </div>
                        </>
                    }
                </div>
                <Badge bg={"dark"}>{file.extension}</Badge> <span>{file.description}</span>
            </div>
        )
    }



    _render() {
        if(this.props.printMode){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderPrintView()}
            </div>
        }else if(this.props.instructorMode){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInstructorView()}
            </div>
        } else if(this.props.showEditorView){
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderEditorView()}
            </div>
        }else{
            return <div>
                {/*JSON.stringify(this.component)*/}
                {this.renderInputView(this.instructorMode)}
            </div>
        }
    }

    render() {

        if(this.state.fetching){
            return <LoadingScreen />;
        }

        return (
            <div className={"fileUpload"}>
                {this._render()}
            </div>
        )
    }

}
export default FileUploadInput;