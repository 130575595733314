import React, {Component} from 'react';

import {Button, Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faBookOpen,
    faComments, faDownload, faFilePdf, faSheetPlastic, faSpinner, faTools,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import APIController from "../../controller/APIController";
import PhaseSelectionBox from "../PhaseSelectionBox";
import StatusBadge from "../badges/StatusBadge";
import PDFDownloadButton from "../PDFDownloadButton";
import UserController from "../../controller/UserController";

class SmartWorksheetActionButtons extends Component {
    constructor(props) {
        super(props);
        this.worksheet = props.worksheet;
        this.traineeId = props.traineeId;
        this.userType = props.userType;
        this.onDeleteWorkingSheet = props.onDeleteWorkingSheet;
        this.onWorksheetUpdated = props.onWorksheetUpdated;
        this.evaluation = props.evaluation?props.evaluation:undefined;
        this.onEvaluationUpdated = props.onEvaluationUpdated?props.onEvaluationUpdated:()=>{};
    }

    calculateButtonVisibilityByState(worksheetState){

        let showPhaseSelectionBox = false;
        let showEditButton = false;
        let showPdfButton = false;
        let showFinalDeleteButton = false;

        if(this.userType === UserController.TYPES.TRAINEE){
            switch (worksheetState) {
                case APIController.WORKSHEET_STATES.DISCARDED_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = false;
                    showPdfButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.GRADED_WORKING_SHEET:
                    showPhaseSelectionBox = true;
                    showEditButton = false;
                    showPdfButton = true;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.OPEN_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = true;
                    showPdfButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.SUBMITTED_WORKING_SHEET:
                    showPhaseSelectionBox = true;
                    showEditButton = false;
                    showPdfButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.DRAFT_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = false;
                    showPdfButton = false;
                    showFinalDeleteButton = false;
                    break;
            }
        }else{
            switch (worksheetState) {
                case APIController.WORKSHEET_STATES.DISCARDED_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = true;
                    showPdfButton = false;
                    showFinalDeleteButton = true;
                    break;
                case APIController.WORKSHEET_STATES.GRADED_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = true;
                    showPdfButton = true;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.OPEN_WORKING_SHEET:
                    showPhaseSelectionBox = true;
                    showEditButton = true;
                    showPdfButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.SUBMITTED_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = true;
                    showPdfButton = false;
                    showFinalDeleteButton = false;
                    break;
                case APIController.WORKSHEET_STATES.DRAFT_WORKING_SHEET:
                    showPhaseSelectionBox = false;
                    showEditButton = true;
                    showPdfButton = false;
                    showFinalDeleteButton = true;
                    break;
            }
        }

        return {
            showPhaseSelectionBox : showPhaseSelectionBox,
            showEditButton : showEditButton,
            showPdfButton :showPdfButton,
            showFinalDeleteButton:showFinalDeleteButton
        }
    }



    static getURLByState(state, worksheetId, traineeId) {
        switch (state) {
            case APIController.WORKSHEET_STATES.DISCARDED_WORKING_SHEET:
                return "/worksheets/" + worksheetId + "?readOnly=true";
            case APIController.WORKSHEET_STATES.GRADED_WORKING_SHEET:
                return "/worksheets/" + worksheetId + "?readOnly=true";
            case APIController.WORKSHEET_STATES.OPEN_WORKING_SHEET:
                return "/worksheets/" + worksheetId;
            case APIController.WORKSHEET_STATES.SUBMITTED_WORKING_SHEET:
                return "/worksheets/" + worksheetId + "?readOnly=true";
            case APIController.WORKSHEET_STATES.DRAFT_WORKING_SHEET:
                return "/worksheets/" + worksheetId + "?readOnly=true";
            default:
                return "/worksheets/" + worksheetId + "?readOnly=true";
        }
    }


    render() {
        var self = this;
        var buttonVis = this.calculateButtonVisibilityByState(this.worksheet.state);
        return (
            <div>


                    {buttonVis.showFinalDeleteButton &&
                        <Button title={"Endgültig Löschen"} onClick={()=>{
                            if (window.confirm("Möchten Sie das Arbeitsblatt wirklich löschen?")) {
                                self.onDeleteWorkingSheet(this.worksheet)
                            }
                        }}
                                variant={'danger'} style={{marginRight:'0.2em'}}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    }
                {buttonVis.showEditButton &&
                    <div style={{display:"inline-block"}}>
                        <StatusBadge onEvaluationUpdated={self.onEvaluationUpdated} changeable={UserController.isInstructor()} smallSize={true} onWorksheetUpdated={self.onWorksheetUpdated} worksheet={this.worksheet}/>
                    </div>
                }

                {buttonVis.showPhaseSelectionBox &&
                    <>
                        <div className={"phaseSwitcher"} style={{display:"inline-block", marginLeft:"0.5em"}}>
                            <PhaseSelectionBox onWorksheetUpdated={self.onWorksheetUpdated} worksheet={this.worksheet}/>
                        </div>
                    </>
                }

                {buttonVis.showPdfButton &&
                    <PDFDownloadButton
                        worksheetId={this.worksheet.id}
                        courseId={this.worksheet.courseId}
                        traineeId={this.worksheet.traineeId}
                        topicId={this.worksheet.topic}
                    />
                }
                </div>
        )
    }
}
export default SmartWorksheetActionButtons;